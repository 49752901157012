import React, { useEffect, useState } from "react";
import {
  Shell,
  Card,
  Text,
  Grid,
  Row,
  Column,
  Modal,
  FeatureArtists,
} from "@StixNext/mojito-ui";
import * as actions from "../actions/performers";
import { connect } from "react-redux";

const OtherPerformers = (props) => {
  let { fetchPerformers, performerList } = props;

  useEffect(() => {
    fetchPerformers();
  }, []);

  return (
    <Shell.Body className="playback-container">
          <FeatureArtists
            featureTitle={"Other Performers"}
            layout="card"
            EnablePopup="true"
            moreButtonTitle="View Full Bio"
            items={performerList}
            className="artistParentDiv"
          ></FeatureArtists>
          {/* {performerList.map((slide, index) => {
            let imageArray = slide.image ? slide.image.split(",") : "";
            let image = imageArray ? imageArray[1] : "";
            return (
              <div
                className="performer_div" style={{width:'18%' , margin:'10px', minWidth: '214px', minHeight: '273px'}}
                key={index}
              >
                <Card
                  key={index}
                  className={"performer_card"}
                    // onClick={() => setIsOpen(true)}
                  noPadding={false}
                  noBoxShadow={false}
                  noBorder={false}
                  noBorderRadius={false}
                >
                  <img src={image} alt="" />
                  <div className="performer_details">
                    <div>
                      <Text as="p" className="performer_name">
                        {slide?.name}
                      </Text>
                      <Text as="p" className="performer_role">
                        {slide?.role}
                      </Text>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })} */}

    </Shell.Body>
  );
};
const mapStateToProps = (state) => {
  return { performerList: state.Performers.performerList };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPerformers: () => dispatch(actions.fetchPerformers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherPerformers);
