import { combineReducers } from "redux";
import { Login } from "./Login";
import { Transaction } from "./Transaction";
import { Products } from "./Products";
import { Programmes } from "./Programmes";
import { Workshop } from "./Workshop";
import { Venue } from "./Venue";
import { VideoOnDemand } from "./VideoOnDemand";
import { Contestants } from "./Contestants";
import { Performers} from "./Performers";

export default combineReducers({
  Login,
  Transaction,
  Products,
  Programmes,
  Workshop,
  Venue,
  VideoOnDemand,
  Contestants,
  Performers
});
