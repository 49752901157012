import React from "react";
import { Chat, BouncerContextHook } from "@StixNext/mojito-ui";

import { useGlobalWorker } from "../worker";

function LiveChat(props) {
  const {
    keycloak: { idTokenParsed = {} },
  } = BouncerContextHook();
  const { session } = props;
  const { name = "", sub: uid = "" } = idTokenParsed;
  const { message_worker, messages, sendMessage } = useGlobalWorker(session);

  React.useEffect(() => {
    message_worker.startSessionChat(session, uid);
  }, [session, uid, message_worker]);

  return (
    <Chat
      highlight={props.highlight}
      user={{ uid, name }}
      messageList={messages}
      sendMessage={(e) => {
        sendMessage({ name, session, text: e, uid });
      }}
      loadCount={10}
    />
  );
}

export default LiveChat;
