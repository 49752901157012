import { getVenue } from "./commonapi/mockData";
// import { api } from "./commonapi/apiPaths"

export const changeVenueValues = (field, value) => {
  return {
    type: "UPDATE_VENUE_VALUE",
    field,
    value,
  };
};

export const fetchVenue = () => {
  return (dispatch) => {
    getVenue()
    // return fetch(api.fetchVenue)
      .then((data) => {
        dispatch(changeVenueValues("venueList", data));
      })
      .catch((err) => {});
  };
};

export const setDateSelected = (date) => {
  return (dispatch) => {
        dispatch(changeVenueValues("dateSelected", date));
  };
};
