import React, { useEffect, useState } from "react";
import vote from "../assets/images/like.svg";
import { Likes, BouncerContextHook } from "@StixNext/mojito-ui";
import * as actions from "../actions/Contestants";
import { connect } from "react-redux";
import { useGlobalWorker } from "../worker";


function LikeBox({slide, contestantsList, changeContestantValues, loginDetails,textcolor}) {
  const [count, setCount] = useState(0);
  const { keycloak } = loginDetails;
  const { sessionId = "" } = keycloak;
  const {
    keycloak: { idTokenParsed = {} },
  } = BouncerContextHook();
  const { name = "", sub: uid = "" } = idTokenParsed;
  const { reaction_worker, reactions, sendReaction } = useGlobalWorker();

  const newContestantsList = (countValue) => {
    return contestantsList.map((video) => {
      if (video.id == slide.programmeNo) {
        video.votecount = countValue
      }
      return video
    })
  }
  const onClick = (e) => {
    if (sessionId) {
      e.stopPropagation();
      sendReaction(slide.programmeNo, { name, session: sessionId, text: 1, uid })
      setCount(count+1)
      changeContestantValues("contestantsList", newContestantsList(count+1));
    }
  }

  useEffect(() => {
    async function getCount() {
      let count = await reaction_worker.getSessionReactionCount(slide.programmeNo);
      setCount(count)
      changeContestantValues("contestantsList", newContestantsList(count));
    }
    getCount()
  }, [slide.programmeNo])

  return (
    <div className="contestantcompvotediv" style={{color:textcolor}}>
      <Likes emoji={<img src={vote} className="contestant_vote_img" />}
        count={count}
        onLikeClick={(e) => onClick(e, slide)}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      loginDetails: state.Login,
      contestantsList: state.Contestants.contestantsList
    }
  }
  
  const mapDispatchToProps = (dispatch) => ({
    changeContestantValues: (f, e) =>
      dispatch(actions.changeContestantValues(f, e))
  });

export default connect(mapStateToProps, mapDispatchToProps)(LikeBox);