
const init = {
  performerList: [],
};

export const Performers = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_PERFORMER_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
