import React, { useEffect } from "react";

import { connect } from "react-redux";
import {
  Shell,
  Stage,
  FeatureArtists,
  Video,
  Text,
  Button,
  BouncerContextHook,
  Card,
} from "@StixNext/mojito-ui";
import useCommonI18n from "../utils/i18nHelpers";
import HANS from "../assets/images/HANS.png";
import LAN from "../assets/images/LAN.png";
import JOSHUA from "../assets/images/JOSHUA.png";
import ANDREW from "../assets/images/ANDREW.png";
import main from "../assets/images/stage.png";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import Bravissimo from "../assets/images/bravissimo.jpg";
import BundleMerchandise768px from "../assets/images/BundleMerchandise-768px(w).jpg";
import BundleMerchandise320px from "../assets/images/BundleMerchandise-320px(w).jpeg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import ticket from "../assets/images/ticket.svg";
import passport from "../assets/images/passport.svg";
import power from "../assets/images/power.svg";
import poster from "../assets/images/poster.svg";
import boxset from "../assets/images/boxset.svg";
import totebag from "../assets/images/totebag.svg";

function Merchandise(props) {
  return (
    <>
      <Shell.Body className={"css-livestreamnow"}>
        <Grid>
          <Row>
            <Column style={{ textAlign: "center" }}>
              <Text
                as="h6"
                className="jjlinText"
                style={{ textAlign: "center" }}
              >
                All pictures shown are for illustration purposes only. Actual
                product may vary. 
                <br/>
                圖片僅用於說明用途，實際產品可能會有所不同。
              </Text>
            </Column>
          </Row>
          <Row>
            <Column className="img_css">
              <img
                className="img_laptop"
                src={BundleMerchandise768px}
                style={{ width: "100%", height: "100%" }}
              />
              <img
                className="img_mobile"
                src={BundleMerchandise320px}
                style={{ width: "100%", height: "100%" }}
              />
            </Column>
          </Row>
        </Grid>
      </Shell.Body>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Merchandise);
