import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import BuyProduct from "./BuyProduct";
import Transaction from "./Transaction";
import TransactionSuccess from "./TransactionSuccess";
import TransactionFailure from "./TransactionFailure";
import GroupPassEntry from "./GroupPassEntry";
import GroupPassSuccess from "./GroupPassSuccess";
import GroupPassFailure from "./GroupPassFailure";
import BuySingle from "./BuySingle";
import BuySeason from "./BuySeason";
import TransactionCondition from "./TransactionCondition";
import SessionRestriction from "./SessionRestriction";

function TransactionBody(props) {
  return (
    <>
      {props.transactionDetails.transactionStatus === 1 && <BuyProduct />}
      {props.transactionDetails.transactionStatus === 2 && <Transaction />}
      {props.transactionDetails.transactionStatus === 3 && (
        <TransactionSuccess />
      )}
      {props.transactionDetails.transactionStatus === 4 && (
        <TransactionFailure />
      )}
      {props.transactionDetails.transactionStatus === 5 && <GroupPassEntry />}
      {props.transactionDetails.transactionStatus === 6 && <GroupPassSuccess />}
      {props.transactionDetails.transactionStatus === 7 && <GroupPassFailure />}
      {props.transactionDetails.transactionStatus === 8 && <BuySingle />}
      {props.transactionDetails.transactionStatus === 9 && (
        <TransactionCondition />
      )}
      {props.transactionDetails.transactionStatus === 10 && (
        <SessionRestriction />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionBody);
