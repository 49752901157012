import React from "react";
import {
  Reaction,
  HahaIcon,
  LikeIcon,
  BouncerContextHook,
} from "@StixNext/mojito-ui";
import { connect } from "react-redux";

import { useGlobalWorker } from "../worker";
import * as actions from "../actions/Contestants";

function LiveReaction(props) {
  const { session } = props;
  const { keycloak } = BouncerContextHook();
  const { idTokenParsed } = keycloak;
  const { preferred_username: name = "", sub: uid = "" } = idTokenParsed;

  const { reaction_worker, reactions, sendReaction } = useGlobalWorker(session);

  const emojiList = {
    1: <img alt="haha-emoji" height="25" width="25" src={"/Hahah.png"}></img>,
    2: <img alt="heart-emoji" height="25" width="25" src={"/heart.png"}></img>,
    3: <img alt="like-emoji" height="25" width="25" src={"/like.png"}></img>,
  };

  React.useEffect(() => {
    props.highlight || props.stopChat
      ? reaction_worker.stopSessionReaction()
      : reaction_worker.startSessionReaction(session, uid);
    if (!props.highlight && !props.stopChat) {
      let count = reaction_worker.getSessionReactionCount(session);
      count.then((value) => {
        let detail = [
          { ...props.contestants.contestantDetail[0], votecount: value },
        ];
        props.changeContestantValues("contestantDetail", detail);
      });
    }
  }, [session, uid, reaction_worker, props.highlight, props.shopChat]);

  return (
    <Reaction
      highlight={props.highlight}
      sendReaction={async (e) => {
        sendReaction(null, { name, session, text: e, uid })
      }}
      messageList={reactions}
      sendReactionDelay={500}
      emojiList={emojiList}
    />
  );
}

const mapStateToProps = (state) => ({
  contestants: state.Contestants,
});

const mapDispatchToProps = (dispatch) => ({
  changeContestantValues: (f, e) =>
    dispatch(actions.changeContestantValues(f, e)),
  fetchContestantDetail: (progammeId) =>
    dispatch(actions.fetchContestantDetail(progammeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveReaction);
