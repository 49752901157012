import { useEffect, useState } from "react";
import { messageWorker } from "./MessageWorker";
import { reactionWorker } from "./ReactionWorker";
import { sessionWorker } from "./SessionWorker";

export default function useGlobalWorker(session, user) {
  const [, setState] = useState();
  const message_worker = messageWorker.getWorker();
  const messages = messageWorker.getMessages();

  const reaction_worker = reactionWorker.getWorker();
  const reactions = reactionWorker.getReactions();

  const session_worker = sessionWorker.getWorker();
  const multipleSession = sessionWorker.getSession();

  function rerender(newState) {
    setState({});
  }

  function sendMessage(message) {
    messageWorker.addMessage(message);
    message_worker.sendMessageToFirebase(message);
  }

  function sendReaction(videoId, reaction) {
    reactionWorker.addReaction(reaction);
    reaction_worker.sendReactionToFirebase(videoId, reaction);
  }

  useEffect(() => {
    messageWorker.subscribe(rerender);
    reactionWorker.subscribe(rerender);
    sessionWorker.subscribe(rerender);

    return () => {
      messageWorker.unsubscribe(rerender);
      reactionWorker.unsubscribe(rerender);
      sessionWorker.unsubscribe(rerender);
    };
  });

  return {
    message_worker,
    reaction_worker,
    session_worker,
    messages,
    reactions,
    multipleSession,
    sendMessage,
    sendReaction,
  };
}
