import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text, Button } from "@StixNext/mojito-ui";

import failed from "../../assets/images/failed.png";
import { theme } from "./../../Theme";

import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";
import moment from "moment-timezone";

function GroupPassFailure(props) {
  // console.log(
  //   "timezone",
  //   moment.tz.guess(),
  //   moment(new Date("Sat Jun 19 2021 07:51:46 GMT+0000"))
  //     .tz("America/Los_Angeles")
  //     .format("YYYYMMDD HH:mm z"),
  //   moment(new Date("Sat Jun 19 2021 07:51:46 GMT+0000"))
  //     .tz(moment.tz.guess())
  //     .format("YYYYMMDD HH:mm:ss z")
  // );
  // useEffect(() => {
  //   var index = props.transactionDetails.gpurchaseFailureMessage.indexOf("at ");
  //   var arr = [
  //     props.transactionDetails.gpurchaseFailureMessage.slice(0, index),
  //     props.transactionDetails.gpurchaseFailureMessage.slice(index + 1),
  //   ];
  //   console.log(
  //     "props",
  //     props.transactionDetails.gpurchaseFailureMessage,
  //     props.transactionDetails.gpurchaseFailureMessage.substring(
  //       props.transactionDetails.gpurchaseFailureMessage.indexOf("at") + 1
  //     ),
  //     arr,
  //     arr[1].split("(Coordinated Universal Time)")
  //   );
  // }, [props.transactionDetails.gpurchaseFailureMessage]);
  return (
    <>
      <Grid>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Column
            xl={5}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={failed}
              alt="icon"
              style={{ width: "166px", height: "166px", opacity: "0.19" }}
            />
          </Column>
          <Column xl={7} xs={12}>
            <Row>
              <Column xl={12}>
                {props.transactionDetails.gpurchaseFailureMessage ==
                "Failure" ? (
                  <>
                    <Text as="p">
                      <Text as="h6" className="fail_text">
                        Sorry, the pass is invalid
                      </Text>
                    </Text>
                    <Text as="p">
                      <Text as="h6" className="failed_txt">
                        Please try again with another access link
                        {/* or{" "}
                        {
                          <a
                            onClick={() => {
                              props.changeTransactionValues("isOpen", true);
                              props.changeTransactionValues(
                                "transactionStatus",
                                1
                              );
                              props.changeTransactionValues(
                                "showDropinComponent",
                                true
                              );
                            }}
                            href="#"
                          >
                            click here{" "}
                          </a>
                        }{" "}
                        to purcase a festival pass. */}
                      </Text>
                    </Text>
                  </>
                ) : (
                  <Text as="p" className="fail_p">
                    <Text as="h6" className="fail_text">
                      {props.transactionDetails.gpurchaseFailureMessage}
                    </Text>
                  </Text>
                )}

                {/* <Text as="p">
                  <Text as="h6" className="failed_txt">
                    Please try again with another access link 
                    or{" "}
                    {
                      <a
                        onClick={() => {
                          props.changeTransactionValues("isOpen", true);
                          props.changeTransactionValues("transactionStatus", 1);
                          props.changeTransactionValues(
                            "showDropinComponent",
                            true
                          );
                        }}
                        href="#"
                      >
                        click here{" "}
                      </a>
                    }{" "}
                    to purcase a festival pass.
                  </Text>
                </Text> */}
              </Column>
            </Row>
          </Column>
        </Row>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupPassFailure);
