import history from "../../stores/history";
import { BouncerContextHook } from "@StixNext/mojito-ui";

// add into session
export const sessionSet = (key, value, expirationInMin = 1) => {
  let expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  //   let newValue = {
  //     value: value,
  //     expirationDate: expirationDate.toISOString(),
  //   };
  localStorage.setItem(key, value);
};

// get from session (if the value expired it is destroyed)
export const sessionGet = (key) => {
  //   const value = BouncerContextHook();
  //   console.log("value", value);
  let stringValue = window.localStorage.getItem(key);

  if (stringValue !== null) {
    let value = stringValue;
    let expirationDate = new Date(value.expirationDate);
    // if (expirationDate > new Date()) {
    return value;
    // } else {
    //   window.localStorage.removeItem(key);
    //   return "Token Expired";
    // }
  } else {
    return "Token Expired";
  }
};

// get from session (if the value expired it is destroyed)
export const ifTokenExpire = (data) => {
  if (
    data.message === "Missing Token" ||
    data.message === "ACCESS DENIED" ||
    data.message === "unable to process request" ||
    data.message === "Unauthorized"
  ) {
    return "Token Expired";
  } else {
    return "";
  }
};
