export const transactionapi = `${process.env.REACT_APP_TRANSACTION_API_DOMAIN}${process.env.REACT_APP_TRANSACTION_API_PATH}`;
export const cms_api = process.env.REACT_APP_CMS_API_DOMAIN
export const api = {
  accessToken: `${transactionapi}/payments/braintree/token`,
  checkout: `${transactionapi}/payments/braintree/checkout`,
  fetchTnxn: '',//`${transactionapi}/transactions/fetchPurchaseByUser`,
  products: `${transactionapi}/products/byClient`,
  gpurchase: `${transactionapi}/offers/redeem`,
  authorizationToken: `${transactionapi}/auth/generateToken`,
  fetchContestants: `${cms_api}Contestants`,
  fetchProgrames: `${cms_api}Programes`,
  fetchVenue: `${cms_api}Venue`,
  fetchWorkshop: `${cms_api}Workshop`,
  fetchPerformers: `${cms_api}performers`,
  fetchVideoOnDemand: `${cms_api}Workshop&vod=true`
};  
