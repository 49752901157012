import { api } from "./commonapi/apiPaths";

export const changePerformerValues = (field, value) => {
  return {
    type: "UPDATE_PERFORMER_VALUE",
    field,
    value,
  };
};

export const fetchPerformers = () => {
  return (dispatch) => {
    return fetch(api.fetchPerformers)
      .then((res) => res.json())
      .then((data) => {
        dispatch(changePerformerValues("performerList", data.data));
      })
      .catch((err) => {});
  };
};

