import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Shell,
  VideoPlayer,
  FeatureArtists,
  Grid,
  Row,
  Column,
  Text,
  Button,
  BouncerContextHook,
  CloseWhiteIcon,
  SisticLivePlayer,
  AddOn,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Arrow from "../assets/images/leftarrow.png";
import PrevArrow from "../assets/images/prev_arrow.svg";
import vote from "../assets/images/like.svg";

import * as contestantActions from "../actions/Contestants";
import history from "../stores/history";
import LiveChat from "./LiveChat";
import LikeBox from "./LikeBox";
// import Rectangle from "../assets/images/rectangle.svg";

function ContestantsDetail(props) {
  const loginContext = BouncerContextHook();
  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        loginContext.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 1000);
  };
  useEffect(() => {
    props.fetchContestants();
    let progammeId = props.match.params.id;
    props.fetchContestantDetail(progammeId);
    return () => {
      props.changeContestantValues("contestantDetail", []);
    };
  }, [props.match.params.id]);

  const getIndex = (list) => {
    let index;
    let detail = props.contestants.contestantDetail[0];
    console.log("detail1", props.contestants.contestantDetail[0]);
    list.map((item, i) => {
      if (detail.programmeNo == item.programmeNo) {
        index = i;
      }
    });
    return index;
  };

  const goToPreviousVideo = () => {
    let list = props.contestants.contestantsList;
    let index = getIndex(list);
    index = index == 0 ? list.length - 1 : index - 1;
    let next = list[index];
    props.history.push(`/performances/${next.programmeNo}`);
  };

  const goToNextVideo = () => {
    let list = props.contestants.contestantsList;
    let index = getIndex(list);
    index = index + 1 == list.length ? (index = 0) : index + 1;
    let next = list[index];
    props.history.push(`/performances/${next.programmeNo}`);
  };

  console.log("calling", props.contestants);
  return (
    <>
      {props.contestants.contestantDetail.length > 0 &&
        props.contestants.contestantDetail.map((value, key) => {
          let imageArray = value.thumbnailImage ? value.thumbnailImage.split(',') : ''
                let image = imageArray ? imageArray[1] : ''

          return (
            <div className="row vodContainer ">
              <div className='container'>
                <div className="videoDiv" style={{marginLeft:'117px'}}>
                <Link
                  to={
                    localStorage.getItem("prevPath")
                      ? localStorage.getItem("prevPath")
                      : "/"
                  }
                  className="title"
                >
                  <img src={Arrow} alt="icon" />
                  
                    {/* <Text>contestants</Text> */}
                    <Text as="captionblock" className="titletext">
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexFlow: "row",
                          alignItems: "center",
                          columnGap: "6px",
                        }}
                      >
                        Youth Group Performances
                      </div>
                      {value.Title}
                      {/* <br />
                        <div style={{ fontSize: "13px", fontWeight: "100" }}>
                          <Moment format="LT">{value.startDateTime}</Moment> till{" "}
                          <Moment format="LT">{value.endDateTime}</Moment>
                        {" @ "}
                         <span style={{ fontWeight: "bold" }}> Hall 2</span>
                                </div> */}
                    </Text>
                  
                </Link>
                </div>
                <div className="contestantcontainer" style={{paddingTop:'0px'}}>
                  <div className="prev_next_whole">
                    {/* {getIndex(props.contestants.contestantsList) > 0 && ( */}
                      <div
                        className="prev_div"
                        onClick={(e) => goToPreviousVideo()}
                      >
                        <img
                          src={PrevArrow}
                          alt="icon"
                          className="prev_img"
                          height="32px"
                          width="32px"
                        />
                        <p className="img_text">Previous Video</p>
                      </div>
                    {/* // )} */}
                    <div className="contestantswholediv">
                      <div className="videodiv voddiv contestantvoddiv disablechat">
                        {!loginContext.sessionRestrictor &&
                          props.loginDetails.authenticated && (
                            <SisticLivePlayer
                              playerItems={{
                                player: false,
                                startDateTime: props.contestants
                                  .contestantDetail.length
                                  ? props.contestants.contestantDetail[0]
                                      .startDateTime
                                  : "",
                                endDateTime: props.contestants.contestantDetail
                                  .length
                                  ? props.contestants.contestantDetail[0]
                                      .endDateTime
                                  : "",
                                authenticated: props.loginDetails.authenticated,
                                hideBuyButton:
                                  props.transactionDetails.hideBuyButton,
                                url: value.videoAssetURL,
                                type: "mp4",
                                trackingId: `${process.env.REACT_APP_GID}`,
                                // poster: "//vjs.zencdn.net/v/oceans.png",
                                enableReaction: true,
                                reactionContent: (
                                  <LikeBox slide={value} textcolor="white" />
                                ),
                                enableChat: props.contestants
                                  .contestantDetail[0].ChatFlag
                                  ? props.contestants.contestantDetail[0]
                                      .ChatFlag
                                  : false,
                                chatContent: (
                                  <LiveChat session={props.match.params.id} />
                                ),
                              }}
                            />
                          )}
                      </div>

                      {/* {props.videoOnDemand.videoOnDemandDetail[0].addOn && (
                    <div className="chatsection">
                      <div
                        style={{
                          height: "477px",
                          background: "white",
                        }}
                      >
                        <div className="chatQAMenu">
                          <div className="chatMenuTitle">
                            <span style={{ cursor: "pointer" }}>
                              <span className="chatMenuTitleSpan chatQAMenuActive">
                                Add-Ons
                              </span>
                              <img
                                className="chatMenuTitleImage chatMenuActive"
                                src={AddonIco}
                              />
                            </span>
                          </div>
                        </div>
                        <span className="menuborder"></span>
                        <AddOn
                          className="addondiv"
                          items={
                            props.videoOnDemand.videoOnDemandDetail.length
                              ? props.videoOnDemand.videoOnDemandDetail[0].addOn
                              : ""
                          }
                        />
                      </div>
                    </div>
                   )} */}
                      {/* {props.loginDetails.authenticated === null ||
                    props.loginDetails.authenticated === false ? (
                      //   ||
                      //   props.transactionDetails.hideBuyButton === false
                      // !value.ChatFlag && !value.QAFlag ? (
                      <div className="mobchatalt"></div>
                    ) : (
                      // ) : (
                      //   ""
                      // )
                      ""
                    )} */}
                      {loginContext.sessionRestrictor ||
                      props.loginDetails.authenticated === null ||
                      props.loginDetails.authenticated === false ? (
                        //props.transactionDetails.hideBuyButton === false ? (

                        <div className="wrap_div">
                          <Text as="h4" className="wrap_title">
                            {"This video is only available upon login "}
                          </Text>

                          {loginContext.sessionRestrictor ||
                          props.loginDetails.authenticated === null ||
                          props.loginDetails.authenticated === false ? (
                            <div>
                              <Text as="h6" className="wrap_pass">
                                {"Login here"}
                              </Text>
                              <Button
                                primary
                                className="video_btn"
                                onClick={(e) => {
                                  // localStorage.setItem("logout", false);
                                  // props.changeTransactionValues(
                                  //   "transactionStatus",
                                  //   10
                                  // );
                                  // props.changeTransactionValues("isOpen", true);
                                  setTimeout(() => {
                                    loginContext.loginKeycloak();
                                  }, 1000);
                                  // loginContext.loginKeycloak();
                                }}
                              >
                                Click here to login
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <LiveChat session={props.match.params.id} /> */}
                      {/* {props.loginDetails.authenticated &&
                    props.transactionDetails.hideBuyButton && ( */}
                      <>
                        {/* {!hideAgeOverlay ? (
                      legalAge ? (
                        <div className="wrap_explore_div">
                          <Text as="h4" className="explore_title">
                            You're not at the legal age to consume the content
                          </Text>
                          <div className="pass_div">
                            <Button
                              primary
                              className="age_button"
                              onClick={() => {
                                history.push("/overview");
                              }}
                            >
                              Explore other content
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="wrap_age_div">
                          <Text as="h4" className="age_title">
                            This content is rated NC16
                            <br />
                            (Violence and coarse language)
                          </Text>
                          <div className="pass_div">
                            <div>
                              <Text as="h6" className="wrap_age">
                                Are you over the age of 16?
                              </Text>
                              <div className="allow_section">
                                <Button
                                  primary
                                  className="age_button"
                                  onClick={() => {
                                    setHideAgeOverlay(true);
                                  }}
                                >
                                  Yes, let me in
                                </Button>
                                <Button
                                  primary
                                  className="age_grey_btn"
                                  onClick={() => {
                                    setLegalAge(true);
                                  }}
                                >
                                  No, I'm not
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      ""
                    )} */}
                      </>
                      {/* )} */}
                    </div>
                    {/* {getIndex(props.contestants.contestantsList) !=
                      props.contestants.contestantsList.length - 1 && ( */}
                      <div
                        className="prev_div"
                        onClick={(e) => goToNextVideo()}
                      >
                        <img
                          src={PrevArrow}
                          alt="icon"
                          className="next_img"
                          height="32px"
                          width="32px"
                        />
                        <p className="img_text">Next Video</p>
                      </div>
                    {/* // )} */}
                  </div>
                </div>
              </div>
              <div className="synopiscontainer synopsismob" style={{paddingLeft: '132px'}}>
                <div className="synopisdiv">
                  <div
                    className={`synopissection ${
                      value.moderator ? "" : "disablemoderator"
                    }`}
                  >
                    <div className="image_section" style={{ display: "flex" }}>
                      <div className="profile-pic">
                        <img src={image} width="249" height="140"/>
                      </div>
                      <div className="intro">
                        <Text as="h6" className="synopsis">
                          INTRODUCTION
                        </Text>
                        <Text as="p">
                          {value.writeUp &&
                            ReactHtmlParser(
                              value.writeUp.replace(/(?:\r\n|\r|\n)/g, "<br />")
                            )}
                        </Text>
                      </div>
                    </div>
                    {/* <div className="bookwebsection">
                      <div className="booksection">
                        {value.donationLink && (
                          <a
                            href={value.donationLink}
                            target="_blank"
                            style={{
                              display: "flex",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              src={Donation}
                              alt="icon"
                              style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                              }}
                            />
                            <Text as="p" className="booktext">
                              Click here to donate <br />
                              to our giving.sg
                            </Text>
                          </a>
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    videoOnDemand: state.VideoOnDemand,
    contestants: state.Contestants,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeContestantValues: (f, e) =>
    dispatch(contestantActions.changeContestantValues(f, e)),
  fetchContestantDetail: (progammeId) =>
    dispatch(contestantActions.fetchContestantDetail(progammeId)),
  fetchContestants: () => dispatch(contestantActions.fetchContestants()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContestantsDetail);
