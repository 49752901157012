const init = {
  contestantsList: [],
  contestantDetail: [],
};

export const Contestants = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_CONTESTANTS_VALUE":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
