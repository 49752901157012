import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Text,
  BouncerContextHook,
  SisticLivePlayerAlt,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as VideoOnDemand from "../actions/VideoOnDemand";
import { VideoRestriction } from "../components/Transaction/VideoRestriction";

const useWindowUnloadEffect = (handler, callOnCleanup) => {
  const cb = useRef();
  cb.current = handler;
  useEffect(() => {
    const handler = () => cb.current();
    window.addEventListener("beforeunload", handler);
    return () => {
      if (callOnCleanup) handler();
      window.removeEventListener("beforeunload", handler);
    };
  }, [cb]);
};

function VODRedeem(props) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [time, setTime] = useState(0);
  const loginContext = BouncerContextHook();
  const { keycloak } = props.loginDetails;
  const { sessionId } = keycloak;
  const vzPlayer = window.vzPlayer;

  useEffect(() => {
    if (props.match.path.includes("vdodetail")) {
      let progammeId = props.match.params.id;
      props.fetchVideoOnDemandDetail(progammeId);
      return () => {
        props.changeVideoOnDemandValues("videoOnDemandDetail", []);
      };
    }
  }, []);

  useWindowUnloadEffect(() => {
    if (loaded) {
      localStorage.setItem("watchTime", JSON.stringify({ sessionId, time }));
    }
  }, true);

  useEffect(() => {
    if (time > 900) {
      setErrorMessage("The video has ended!");
      setModalOpen(true);
      setTimeout(() => props.loginDetails.keycloak.logout(), 5000);
    }
  }, [time]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const redeemAPi = async (e) => {
    e.preventDefault();
    let passId = props.match.params.id;
    if (props.loginDetails.user.userid && passId) {
      let res = await props.fetchVideoOnRedeem(props.loginDetails.user, passId);
      if (res.count) {
        props.changeVideoOnDemandValues("videoOn", true);
      } else if (res.message) {
        setErrorMessage(res.message);
        setModalOpen(true);
      } else {
        setErrorMessage("Unable to process your request");
        setModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (loaded) {
      let vzp = new vzPlayer("vzvd-23213746");
      vzp.ready(() => {
        let wT = localStorage.getItem("watchTime");
        if (wT) {
          let watchTime = JSON.parse(wT);
          if (sessionId == watchTime.sessionId) {
            vzp.seekTo(watchTime.time);
          }
        }
        vzp.addEventListener("EVENT_TIME_UPDATE", (e) => setTime(e));
      });
    }
  }, [loaded]);

  const iframeOnLoad = () => {
    setLoaded(true);
  };

  return (
    <>
      <div className="row vodContainer">
        <div className="container">
          <div className="wholediv">
            <div className="videodiv voddiv disablechat">
              {/* {!loginContext.sessionRestrictor &&
                props.loginDetails.authenticated &&
                props.videoOnDemand.videoOn &&
                !errorMessage && ( */}
              <SisticLivePlayerAlt
                className="live-player"
                iframeItems={{
                  id: "vzvd-23213746",
                  name: "vzvd-23213746",
                  title: "video player",
                  className: "video-player video-player-responsive",
                  frameborder: "0",
                  style: { position: "absolute", top: 0, left: 0 },
                  onLoad: iframeOnLoad,
                }}
                playerItems={{
                  src: "https://view.vzaar.com/23213746/player",
                  stopChat: true,
                }}
              />
              {/* )} */}
            </div>
            {props.loginDetails.authenticated === null ||
            props.loginDetails.authenticated === false ||
            props.videoOnDemand.videoOn === false ? (
              <div className="mobchatalt"></div>
            ) : (
              ""
            )}
            {/* {loginContext.sessionRestrictor ||
            props.loginDetails.authenticated === null ||
            props.loginDetails.authenticated === false ||
            props.videoOnDemand.videoOn === false ? (
              <div className="wrap_div">
                <Text as="h4" className="redeem_title">
                  {"[IMPORTANT NOTICE]"}
                  <br />
                  <strong>{"By logging in, you agree and accept that:"}</strong>
                  <ul style={{ paddingLeft: "20px" }}>
                    <li>
                      The virtual concert must be viewed in the same session
                    </li>
                    <li>There are no playbacks or pauses</li>
                    <li>
                      You will not be able to return to view the concert once
                      you log in
                    </li>
                    <li>The viewing device cannot be switched.</li>
                  </ul>
                  NOTE: If you wish to view the concert another time, please
                  exit the page now and return when you are ready. The encore
                  stream will expire on 30 July 2021 2359hrs
                </Text>
                <div>
                  <Button
                    primary
                    className="video_btn"
                    disabled={
                      props.loginDetails.authenticated === null ||
                      props.loginDetails.authenticated === false
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      redeemAPi(e);
                    }}
                  >
                    proceed to watch
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <div className="synopiscontainer">
          <div className="synopisdiv">
            <div className={"synopissection disablemoderator"}>
              <Text as="h6" className="synopsis">
                Short Introduction
              </Text>
              <Text as="p">
                {ReactHtmlParser(
                  `This is not just any ordinary performance event for group participants or music lovers, but it is your chance to experience something new that is the first of its kind. You will be able to attend masterclasses by commissioned youth composers, be mentored by stellar professional musicians (some of whom are legends in our music industry) through workshops/clinics, and also get a chance to perform with them on the same virtual stage at the final NXT – A Virtual Music Event premiering on 21 January 2022 at 8pm.`.replace(
                    /(?:\r\n|\r|\n)/g,
                    "<br />"
                  )
                )}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <VideoRestriction
        isOpen={modalOpen}
        errorMessage={errorMessage}
        onClose={closeModal}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    videoOnDemand: state.VideoOnDemand,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeVideoOnDemandValues: (f, e) =>
    dispatch(VideoOnDemand.changeVideoOnDemandValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchVideoOnDemandDetail: (progammeId) =>
    dispatch(VideoOnDemand.fetchVideoOnDemandDetail(progammeId)),
  fetchVideoOnRedeem: (user, programmeId) =>
    dispatch(VideoOnDemand.fetchVideoOnRedeem(user, programmeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VODRedeem);
