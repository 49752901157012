import { getContestants } from "./commonapi/mockData";
import { api } from "./commonapi/apiPaths"


export const changeContestantValues = (field, value) => {
  return {
    type: "UPDATE_CONTESTANTS_VALUE",
    field,
    value,
  };
};

export const fetchContestants = () => {
  return (dispatch) => {
    // getContestants()
    return fetch(api.fetchContestants)
    .then((res) => res.json())
      .then((data) => {
        dispatch(changeContestantValues("contestantsList", data.data));
      })
      .catch((err) => {});
  };
};

export const fetchContestantDetail = (progammeId) => {
  return (dispatch) => {
    // getContestants()
    return fetch(api.fetchContestants)
    .then((res) => res.json())
      .then((data) => {
        let programmeDetail = data.data.filter(function (item) {
          return item.programmeNo == progammeId;
        });
        console.log('programmeDetail',programmeDetail)
        dispatch(changeContestantValues("contestantDetail", programmeDetail));
      })
      .catch((err) => {});
  };
};
