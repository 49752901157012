import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import * as actions from "../actions/Contestants";
import { connect } from "react-redux";
import userEvent from "@testing-library/user-event";
import LikeBox from "./LikeBox";

const Contestants = (props) => {
  let { contestantsList } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [category, setCategory] = useState([]);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleClick = (eventId) => {
    props.history.push(`/performances/${eventId}`);
  };

  useEffect(() => {
    props.fetchContestants();
    // let newContestantsList = [];
    // if (props.contestantsList.length > 0) {
    //   props.contestantsList.map(async (video) => {
    //     let count = reaction_worker.getSessionReactionCount(video.id);
    //     await count.then((value) => {
    //       video["votecount"] = value;
    //       newContestantsList.push(video);
    //     });
    //     if (newContestantsList.length === props.contestantsList.length) {
    //       props.changeContestantValues("contestantsList", newContestantsList);
    //     }
    //   });
    // }
  }, []);
  // useEffect(() => {
  //   let cat = [];
  //   contestantsList.map((video) => {
  //     let { Category } = video;
  //     if (Category) {
  //       cat.push(Category);
  //     }
  //   });
  //   let uniqueCat = cat.sort().filter((v, i, a) => a.indexOf(v) === i);
  //   setCategory(uniqueCat);
  //   let newContestantsList = [];
  //   if (!props.contestantsList[0]?.votecount) {
  //     contestantsList.map(async (video) => {

  //       // await count.then((value) => {
  //       //   video["votecount"] = value;
  //       //   newContestantsList.push(video);
  //       // });
  //       // if (newContestantsList.length === props.contestantsList.length) {
  //       //   props.changeContestantValues("contestantsList", newContestantsList);
  //       // }
  //     });
  //   }
  // }, [contestantsList]);

  // const filterCategory = (data, selectedCategory) => {
  //   let { Category = null } = data;

  //   if (Category) {
  //     let cat = Category.toString().split(",");
  //     let result = cat.filter((d) => d == selectedCategory.toString());
  //     if (result.length > 0) {
  //       return data;
  //     }
  //   }
  // };

  console.log('contestantsList',contestantsList)

  return (
    <Shell.Body className="css-watch-free">
      {/* {category.map((cat, index) => {
        return ( */}
      <>
        <Grid>
          <Row className="ondemand_row">
            {/* <Column xl={4} lg={4} md={3} sm={6} xs={12}> */}
            {/* <Text as={"h5"} className={"upcoming-title"}>
                    {cat}
                  </Text> */}

            {contestantsList.length > 0 &&
              contestantsList.map((slide, index) => {
                console.log('value1',slide)
                // console.log(
                //   "slide",
                //   slide.id
                //     .toString()
                //     .slice(
                //       slide.id.toString().length - 1,
                //       slide.id.toString().length
                //     )
                // );

                // let images = Images[`Image_100${slide.programmeNo.toString()}`]
                //   ? Images[`Image_100${slide.programmeNo.toString()}`]
                //   : Images[`defaultImage`];

                let imageArray = slide.thumbnailImage ? slide.thumbnailImage.split(',') : ''
                let image = imageArray ? imageArray[1] : ''

                return (
                  <div className="contestant-tile" key={index} >
                    <Card
                        className={"contestant-slides"}
                        onClick={() => handleClick(slide?.programmeNo)}
                        noPadding={false}
                        noBoxShadow={false}
                        noBorder={true}
                        noBorderRadius={true}
                      >
                        <img src={image} alt="" />
                        <div>
                        <div className={"title-tiles vod-tiles"} dangerouslySetInnerHTML={{ __html: slide?.Title }}/>
                          {/* <Text
                            as="div"
                            className={"title-tiles contestant-tiles"}
                          >
                            {slide?.title}
                          </Text> */}
                          {/* <Text as="p" className="contestant-p">
                            {"Duration: " + slide?.duration + " secs"} <br />
                            {"Uploaded on: " + slide?.updatedDate} <br />
                          </Text> */}
                          <p>{slide.crewName}</p>
                          <LikeBox slide={slide} /> 
                      </div>
                    </Card>
                  </div>
                );
              })}
            {/* </Column> */}
          </Row>
        </Grid>
        {/* {index !== category.length - 1 && (
          <Grid>
            <Row>
              <Column style={{ padding: "10px 0" }}>
                <dv className={"line-in-upcoming"} />
              </Column>
            </Row>
          </Grid>
        )} */}
      </>
      {/* })} */}
    </Shell.Body>
  );
};
const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    contestantsList: state.Contestants.contestantsList
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeContestantValues: (f, e) =>
    dispatch(actions.changeContestantValues(f, e)),
  fetchContestants: () => dispatch(actions.fetchContestants()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contestants);
