import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Shell,
  VideoPlayer,
  FeatureArtists,
  Grid,
  Row,
  Column,
  Text,
  Button,
  BouncerContextHook,
  CloseWhiteIcon,
  DownArrowIcon,
  SisticLivePlayerAlt,
  SisticLivePlayer,
  AddOn,
  Reaction,
  HahaIcon,
  HeartReactionIcon,
  LikeIcon,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";
import Moment from "react-moment";
import livestreamdot from "../assets/images/icons/livestream_dot.svg";
import vote from "../assets/images/like.svg";
import Book from "../assets/images/book.png";
import BookStore from "../assets/images/bookstore.jpg";
import Donation from "../assets/images/donation.png";
import SecondScreen from "../assets/images/second_screen.png";
import Arrow from "../assets/images/leftarrow.png";
import ChatIco from "../assets/images/icons/chat_icon_black.svg";
import QAIco from "../assets/images/icons/qa_icon_black.svg";
import PollIco from "../assets/images/icons/poll_icon_black.svg";
import AddonIco from "../assets/images/icons/star_icon_black.svg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import Keycloak from "keycloak-js";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as VideoOnDemand from "../actions/VideoOnDemand";
import * as workshopActions from "../actions/Workshop";
import history from "../stores/history";
import Chat from "@StixNext/mojito-ui/dist/Chat/Chat";
// import VideoOverlay from "../assets/images/video_overlay.jpg";
import LiveChat from "./LiveChat";
import LiveReaction from "./LiveReaction";
import DialogueImage from "./../assets/images/dialogue_placeholder.png";
import MentorshipImage from "./../assets/images/mentorship_placeholder.jpg";

import Govin from "../assets/images/govin.png";
import Rit from "../assets/images/rit.png";
import Sebastian from "../assets/images/sebastian.png";
import Colin from "../assets/images/colin.png";
import Felix from "../assets/images/felix.png";
import Fabian from "../assets/images/fabian.png";
import Tama from "../assets/images/tama.png";
import Lee from "../assets/images/lee.png";
import Syafiqah from "../assets/images/syafiqah.png";
import Terence from "../assets/images/terrence.png";

import Placeholder from "../assets/images/video_placeholder.jpg";

function WorkshopDetail(props) {
  const { history, location = {} } = props;
  const loginContext = BouncerContextHook();
  const [ChatOrQA, setChatOrQA] = useState("addon");
  const [play, setPlay] = useState(false);
  const [end, setEnd] = useState(false);
  const [start, setStart] = useState(false);
  const [legalAge, setLegalAge] = useState(false);
  const [hideAgeOverlay, setHideAgeOverlay] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [fullscreenEnable, setFullScreenEnable] = useState(false);
  const [addOnSection, setAddOnSection] = useState([]);
  const [addonStart, setAddonStart] = useState(false);
  const [addonStop, setAddonStop] = useState(false);
  const [stopChat, setStopChat] = useState(false);

  useEffect(() => {
    let progammeId = props.match.params.id;
    if (process.env.REACT_APP_DRYRUN_IDS.includes(progammeId)) {
      props.fetchDryRunDetail(progammeId);
    } else {
      props.fetchWorkshopDetail(progammeId);
    }

    return () => {
      props.changeWorkshopValues("workshopDetail", []);
    };
  }, []);

  const onToggle = () => setStopChat(!stopChat);

  const toParse = (Array) => {
    let mentorDetail = JSON.parse(Array);
    let mentorDetailWithImage = mentorDetail.map(
      ({ name, description, ...rest }) => ({
        image:
          name == "Syafiqah’ Adha Sallehin"
            ? Syafiqah
            : name == "Terence Wong"
            ? Terence
            : name == "Lee Jin Jun"
            ? Lee
            : name == "Tama Goh"
            ? Tama
            : name == "Fabian Lim"
            ? Fabian
            : name == "Colin Yong"
            ? Colin
            : name == "Felix Phang"
            ? Felix
            : name == "Sebastian Ho"
            ? Sebastian
            : name == "Rit Xu"
            ? Rit
            : name == "Govin Tan"
            ? Govin
            : "",
        name,
        description,
        ...rest,
      })
    );
    return mentorDetailWithImage;
  };

  return (
    <>
      {props.workshop.workshopDetail.length > 0 &&
        props.workshop.workshopDetail.map((value, key) => {
          console.log("value", value);
          return (
            <div className="row vodContainer">
              <div className="container">
                <Link
                  to={
                    localStorage.getItem("prevPath")
                      ? localStorage.getItem("prevPath")
                      : "/"
                  }
                  className="title"
                >
                  <img src={Arrow} alt="icon" />
                  {/* <p>text</p> */}
                  <Text as="captionblock" className="titletext">
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexFlow: "row",
                        alignItems: "center",
                        columnGap: "6px",
                      }}
                    >
                      Live stream <img src={livestreamdot} alt="icon" />
                    </div>
                    {value.title}
                    <br />
                    <div style={{ fontSize: "13px", fontWeight: "100" }}>
                      <Moment format="hh:mm A ">{value.date}</Moment>
                      {" till "}
                      <Moment format="hh:mm A ">
                        {moment(value.date).add(value.duration, "minutes")}
                      </Moment>
                      {" @ "}
                      <span style={{ fontWeight: "bold" }}>NXT</span>
                    </div>
                    {/* <div style={{ fontSize: "13px", fontWeight: "100" }}>
                      { `${new Date(value.startTime).toLocaleString()} @ NXT`}
                    </div> */}
                  </Text>
                </Link>
                <div className="wholediv">
                  <div
                    className="videodiv disablechat"
                    // className={`videodiv ${
                    //   value.ChatFlag || value.QAFlag || value.addOn.length > 0
                    //     ? ""
                    //     : "disablechat"
                    // }`}
                  >
                    {!value.videoURL ? (
                      <div className="show-live">
                        <img
                          src={
                            value.programmeTrack == "Master-Class"
                              ? Placeholder
                              : value.programmeTrack == "Dialogue Session"
                              ? DialogueImage
                              : MentorshipImage
                          }
                          alt=""
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="frame_div"> */}
                    {!loginContext.sessionRestrictor &&
                      value.videoURL &&
                      props.loginDetails.authenticated && (
                        //props.transactionDetails.hideBuyButton && (
                        // <iframe
                        //   src={value.videoURL}
                        //   width="100%"
                        //   height="100%"
                        //   frameborder="0"
                        //   scrolling="no"
                        //   allow="autoplay"
                        //   autoplay="true"
                        //   allowFullScreen="true"
                        //   webkitallowfullscreen="true"
                        //   mozallowfullscreen="true"
                        //   oallowfullscreen="true"
                        //   msallowfullscreen="true"
                        // ></iframe>

                        <SisticLivePlayerAlt
                          // bgColor="white"
                          // color="black"
                          className="video-player"
                          playerItems={{
                            src: value.videoURL,
                            stopChat: value.chatFlag ? !value.chatFlag : true,
                            enableChat: value.ChatFlag ? value.ChatFlag : false,
                            chatContent: (
                              <LiveChat session={props.match.params.id} />
                            ),
                            // enableReaction: true,
                            // reactionContent: (
                            //   <div className="in-player-likes">
                            //     <LikeBox slide={value} />
                            //   </div>
                            // ),
                          }}
                        />
                        // <SisticLivePlayerAlt
                        //   playerItems={{
                        //     player: false,
                        //     playing: true,
                        //     muted: true,
                        //     start: start,
                        //     startDateTime: props.workshop.workshopDetail.length
                        //       ? props.workshop.workshopDetail[0].startDateTime
                        //       : "",
                        //     endDateTime: props.workshop.workshopDetail.length
                        //       ? props.workshop.workshopDetail[0].endDateTime
                        //       : "",
                        //     authenticated: props.loginDetails.authenticated,
                        //     hideBuyButton:
                        //       props.transactionDetails.hideBuyButton,

                        //     url: value.videoURL,
                        //     type: "mp4",
                        //     trackingId: `${process.env.REACT_APP_GID}`,
                        //     disableDuration: true,
                        //     disableSeekBar: true,
                        //     // poster: "//vjs.zencdn.net/v/oceans.png",
                        //     // enableReaction: true,
                        //     // reactionContent: (
                        //     //   <LiveReaction
                        //     //     highlight={false}
                        //     //     session={props.match.params.id}
                        //     //     stopChat={stopChat}
                        //     //   />
                        //     // ),
                        //     enableChat: value.ChatFlag ? value.ChatFlag : false,
                        //     chatContent: (
                        //       <LiveChat session={props.match.params.id} />
                        //     ),
                        //   }}
                        // />
                      )}

                    {/* </div> */}
                    {/* <div
                      className={`secondscreen_div ${
                        value.ChatFlag || value.QAFlag ? "" : "disablechat"
                      } ${fullscreenEnable ? "fullsecond_screen" : ""}`}
                    >
                      <div
                        className="icon_div"
                        onClick={() => {
                          if (fullscreenEnable) {
                            setShowDropdown(true);
                          } else {
                            setShowDropdown(false);
                          }
                        }}
                        onMouseEnter={() => {
                          if (fullscreenEnable) {
                            setShowDropdown(true);
                          } else {
                            setShowDropdown(false);
                          }
                        }}
                      >
                        <h6 className="screen_title">second screen</h6>
                        <img
                          src={Arrow}
                          className={`arrow_div ${
                            fullscreenEnable && !showDropdown ? "show" : ""
                          }`}
                        />
                        <CloseWhiteIcon
                          className={`close_div ${
                            fullscreenEnable && showDropdown ? "show" : ""
                          }`}
                          onClick={() => setShowDropdown(false)}
                        />
                      </div>
                      <div
                        className="mob_icon_div"
                        onClick={() => {
                          if (!showDropdown) {
                            setShowDropdown(true);
                          } else {
                            setShowDropdown(false);
                          }
                        }}
                      >
                        <h6 className="screen_title">second screen</h6>
                        <img
                          src={Arrow}
                          className={`arrow_div ${!showDropdown ? "show" : ""}`}
                        />
                        <CloseWhiteIcon
                          className={`close_div ${showDropdown ? "show" : ""}`}
                          onClick={() => setShowDropdown(false)}
                        />
                      </div>
                      <div
                        id="scroll_div"
                        className={`scroll_div ${
                          showDropdown ? "scorll_show" : ""
                        } ${
                          fullscreenEnable
                            ? showDropdown
                              ? "fullscreen_show"
                              : "fullscreen_close"
                            : ""
                        }`}
                      >
                        <p className="content_div">
                          This is the section to suppliment the event , it will
                          be in running text
                        </p>
                        <div className="thumb_div">
                          <img
                            src={SecondScreen}
                            className="img_div"
                            alt="icon"
                          />
                          <span className="thumb_cont">
                            It can be thumbnail, and also supporting text
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ? (
                    //   ||
                    //   props.transactionDetails.hideBuyButton === false
                    // !value.ChatFlag && !value.QAFlag ? (
                    <div className="mobchatalt"></div>
                  ) : (
                    // ) : (
                    //   ""
                    // )
                    ""
                  )}
                  {/* {value.ChatFlag || value.QAFlag || value.addOn.length > 0 ? (
                    <div className="chatsection">
                      <div
                        style={{
                          height: "477px",
                          background: "white",
                        }}
                      >
                        <div className="chatQAMenu">
                          {value.addOn.length > 0 && (
                            <div className="chatMenuTitle">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setChatOrQA("addon")}
                              >
                                <span
                                  className={`chatMenuTitleSpan ${
                                    ChatOrQA === "addon" && "chatQAMenuActive"
                                  }`}
                                >
                                  Add-Ons
                                </span>
                                <img
                                  className={`chatMenuTitleImage ${
                                    ChatOrQA === "addon" && "chatMenuActive"
                                  }`}
                                  src={AddonIco}
                                />
                              </span>
                            </div>
                          )}
                          {value.ChatFlag && (
                            <div className="chatMenuTitle">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setChatOrQA("chat")}
                              >
                                <span
                                  className={`chatMenuTitleSpan ${
                                    ChatOrQA === "chat" && "chatQAMenuActive"
                                  }`}
                                >
                                  Chat
                                </span>
                                <img
                                  className={`chatMenuTitleImage ${
                                    ChatOrQA === "chat" && "chatMenuActive"
                                  }`}
                                  src={ChatIco}
                                />
                              </span>
                            </div>
                          )}
                          {value.QAFlag && (
                            <div className="chatMenuTitle">
                              <span onClick={() => setChatOrQA("QA")}>
                                <span
                                  className={`chatMenuTitleSpan ${
                                    ChatOrQA === "QA" && "chatQAMenuActive"
                                  }`}
                                >
                                  Q&A
                                </span>
                                <img
                                  className={`chatMenuTitleImage ${
                                    ChatOrQA === "qa" && "chatMenuActive"
                                  }`}
                                  src={QAIco}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        <span className="menuborder"></span>
                        {ChatOrQA === "chat" ? (
                          <div>
                            <Iframe
                              url={
                                process.env.PUBLIC_URL +
                                `/chat.html?${value.ChatSessionId}`
                              }
                              width="100%"
                              height="413px"
                              id="myId"
                              className="pigeonhole-iframe"
                              display="initial"
                              position="relative"
                            />
                          </div>
                        ) : ChatOrQA === "QA" ? (
                          <div>
                            <Iframe
                              url={
                                process.env.PUBLIC_URL +
                                `/qa.html?${value.QASessionId}`
                              }
                              width="100%"
                              height="413px"
                              id="myId"
                              className="pigeonhole-iframe"
                              display="initial"
                              position="relative"
                            />
                          </div>
                        ) : (
                          ChatOrQA === "addon" && (
                            <AddOn className="addondiv" items={value.addOn} />
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/*!play && start && (
                    <div
                      // style={{
                      //   backgroundImage: `url(${VideoOverlay})`,
                      // }}
                      className={"text_div disablechat"}
                    >
                      <img src={VideoOverlay} className="video_over_img" />
                      <p className="video_over_cont">
                        Livestream will start on
                        <br />
                        <Moment format="DD MMM YYYY">
                          {value.startDateTime}
                        </Moment>{" "}
                        | <Moment format="LT">{value.startDateTime}</Moment> SGT
                      </p>
                    </div>
                  )*/}

                  {loginContext.sessionRestrictor ||
                  props.loginDetails.authenticated === null ||
                  props.loginDetails.authenticated === false ? (
                    //props.transactionDetails.hideBuyButton === false ? (

                    <div className="wrap_div">
                      <Text as="h4" className="wrap_title">
                        {"This video is only available upon login "}
                        <br />
                      </Text>

                      {loginContext.sessionRestrictor ||
                      props.loginDetails.authenticated === null ||
                      props.loginDetails.authenticated === false ? (
                        <div>
                          <Text as="h6" className="wrap_pass">
                            {"Login here"}
                            <br />
                          </Text>
                          <Button
                            primary
                            className="video_btn"
                            onClick={(e) => {
                              // localStorage.setItem("logout", false);
                              // props.changeTransactionValues(
                              //   "transactionStatus",
                              //   10
                              // );
                              // props.changeTransactionValues("isOpen", true);
                              setTimeout(() => {
                                loginContext.loginKeycloak();
                              }, 1000);
                              // loginContext.loginKeycloak();
                            }}
                          >
                            Click here to login
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="synopiscontainer">
                {/* <div className="votediv">
                  <img src={vote} className="vote_img" />
                  {value.votecount ? value.votecount : 0}
                </div> */}

                <div className="synopisdiv">
                  <div className="synopissection">
                    <Text as="h5" className="synopsis">
                      Introduction
                    </Text>
                    <Text as="p">
                      {value.writeUp &&
                        ReactHtmlParser(
                          value.writeUp
                            .replace(/(?:\r\n|\r|\n)/g, "<br />")
                            .replace(/(?:\r\t|\r|\t)/g, "&nbsp;")
                        )}
                    </Text>
                    {/* <div className="bookwebsection">
                      <div className="booksection">
                        {value.donationLink && (
                          <a
                            href={value.donationLink}
                            target="_blank"
                            style={{
                              display: "flex",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              src={Donation}
                              alt="icon"
                              style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                              }}
                            />
                            <Text as="p" className="booktext">
                              Click here to donate <br />
                              to our giving.sg
                            </Text>
                          </a>
                        )}
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="chatsection">
                    <div className="videoDetailFeature">
                      <Text as="h4" className="artist">
                        <b>FEATURE ARTIST</b>
                      </Text>
                      <Text as="h6" className="festure-presenter">
                        {value.featuredPresenters &&
                          ReactHtmlParser(
                            value.featuredPresenters.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br />"
                            )
                          )}
                      </Text>
                      <Text as="p">
                        {value.writeUp &&
                          ReactHtmlParser(
                            value.writeUp.replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                      </Text>
                    </div>
                    <div className="videoDetailFeature">
                      <Text as="h6">
                        <b>Moderator</b>
                      </Text>
                      <Text as="p">
                        {value.moderator &&
                          ReactHtmlParser(
                            value.moderator.replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                      </Text>
                    </div>
                  </div> */}
                </div>

                <FeatureArtists
                  featureTitle={value.featureTitle}
                  layout="vertical"
                  EnablePopup="true"
                  moreButtonTitle="View Full Bio"
                  items={toParse(
                    props.workshop.workshopDetail &&
                      props.workshop.workshopDetail[0].mentors
                  )}
                ></FeatureArtists>
              </div>
              {/* <div className="bookcontainer">
                <div className="bookdiv">
                  <div className="bookmobsection">
                    {value.bookstoreLink && (
                      <a
                        href={`https://${value.bookstoreLink}`}
                        target="_blank"
                        style={{
                          display: "flex",
                          paddingLeft: "15px",
                          paddingBottom: "32px",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src={BookStore}
                          alt="icon"
                          style={{ width: "110px", height: "170px" }}
                        />
                        <Text as="p" className="booktext">
                          Visit swfbooks.com
                        </Text>
                      </a>
                    )}
                    {value.donationLink && (
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "15px",
                          paddingBottom: "32px",
                        }}
                      >
                        <img
                          src={Donation}
                          alt="icon"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                        <Text as="p" className="booktext">
                          Click here to donate <br />
                          to our giving.sg
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          );
        })}
      {/* <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    videoOnDemand: state.VideoOnDemand,
    workshop: state.Workshop,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeVideoOnDemandValues: (f, e) =>
    dispatch(VideoOnDemand.changeVideoOnDemandValues(f, e)),
  changeWorkshopValues: (f, e) =>
    dispatch(workshopActions.changeWorkshopValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchVideoOnDemandDetail: (progammeId) =>
    dispatch(VideoOnDemand.fetchVideoOnDemandDetail(progammeId)),
  fetchWorkshopDetail: (progammeId) =>
    dispatch(workshopActions.fetchWorkshopDetail(progammeId)),
  fetchDryRunDetail: (progammeId) =>
    dispatch(workshopActions.fetchDryRunDetail(progammeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopDetail);
