import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import * as actions from "../actions/VideoOnDemand";
import { connect } from "react-redux";
import ComingSoon from "./../assets/images/coming_soon.jpg";
import DialogueImage from "./../assets/images/dialogue_placeholder.png";
import MentorshipImage from "./../assets/images/mentorship_placeholder.jpg";
import Bravissimo from "../assets/images/bravissimo-thumbnail.jpg";

const VideoOnDemand = (props) => {
  let { videoOnDemandList } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [category, setCategory] = useState([]);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const handleClick = (eventId = "FP2") => {
    if (props.loginDetails.authenticated && eventId === "FC1") {
      props.history.push(`/voddetail/${eventId}`);
      window.open("https://ssf20-nxt.xctuality.com/", "_blank");
    } else {
      props.history.push(`/voddetail/${eventId}`);
    }
  };

  useEffect(() => {
    props.fetchVideoOnDemand();
  }, []);

  useEffect(() => {
    let category = videoOnDemandList.map((video) => {
      let { programmeTrack } = video;
      return programmeTrack;
    });
    let uniqueCat = category.sort().filter((v, i, a) => a.indexOf(v) === i);
    setCategory(uniqueCat);
  }, [videoOnDemandList]);

  const filterCategory = (data, selectedCategory) => {
    let { programmeTrack } = data;
    if (programmeTrack == selectedCategory) {
      return data;
    }
  };

  const placeHolder = ({ programmeTrack, id }) => {
    let images = Images[
      `Image_100${id
        .toString()
        .slice(id.toString().length - 1, id.toString().length)}`
    ]
      ? Images[
          `Image_100${id
            .toString()
            .slice(id.toString().length - 1, id.toString().length)}`
        ]
      : Images[`defaultImage`];

    if (programmeTrack === "Master-Class") return images;
    else if (programmeTrack === "Dialogue Session") return DialogueImage;
    else if (id === "FC1") return Bravissimo;
    else return MentorshipImage;
  };

  return (
    <Shell.Body className={"css-work-shop"}>
      <>
        <Grid>
          <Row>
            {
              videoOnDemandList.length > 0 &&
                videoOnDemandList.map((slide, index) => {
                  return (
                    <Card
                      key={index}
                      className={"video-slides"}
                      onClick={() => handleClick(slide?.id)}
                      noPadding={false}
                      noBoxShadow={false}
                      noBorder={true}
                      noBorderRadius={true}
                    >
                      <img src={placeHolder(slide)} alt="" />
                      <div>
                        <Text as="div" className={"title-tiles vod-tiles"}>
                          {slide?.title}
                        </Text>
                        <Text as="p" style={{ fontSize: "16px" }}>
                          {"Duration: " + slide?.duration + " mins"}{" "}
                        </Text>
                      </div>
                    </Card>
                  );
                })
              // </Carousel>
            }
            {/* </Column> */}
          </Row>
          <Column />
        </Grid>
      </>
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  videoOnDemandList: state.VideoOnDemand.videoOnDemandList,
  loginDetails: state.Login,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVideoOnDemand: () => dispatch(actions.fetchVideoOnDemand()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoOnDemand);
