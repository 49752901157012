import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Shell,
  VideoPlayer,
  FeatureArtists,
  Grid,
  Row,
  Column,
  Text,
  Button,
  BouncerContextHook,
  CloseWhiteIcon,
  SisticLivePlayerAlt,
  AddOn,
} from "@StixNext/mojito-ui";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";
import Moment from "react-moment";
import Zadie from "../assets/images/Zadie.png";
import Book from "../assets/images/book.png";
import BookStore from "../assets/images/bookstore.jpg";
import Donation from "../assets/images/donation.png";
import Arrow from "../assets/images/leftarrow.png";
import SecondScreen from "../assets/images/second_screen.png";
import ChatIco from "../assets/images/chat.png";
import QAIco from "../assets/images/Q&A.png";
import AddonIco from "../assets/images/icons/star_icon_black.svg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import Keycloak from "keycloak-js";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import * as progrmmeActions from "../actions/Programmes";
import * as VideoOnDemand from "../actions/VideoOnDemand";
import history from "../stores/history";
import LiveChat from "./LiveChat";
import Chat from "@StixNext/mojito-ui/dist/Chat/Chat";
import { VideoRestriction } from "../components/Transaction/VideoRestriction";
import { deviceDetect } from "react-device-detect";

function VODRedeem(props) {
  const [seconds, setSeconds] = React.useState(900);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const loginContext = BouncerContextHook();

  useEffect(() => {
    if (props.match.path.includes("vdodetail")) {
      let progammeId = props.match.params.id;
      props.fetchVideoOnDemandDetail(progammeId);
      return () => {
        props.changeVideoOnDemandValues("videoOnDemandDetail", []);
      };
    }
  }, []);

  useEffect(() => {
    if (
      !loginContext.sessionRestrictor &&
      props.loginDetails.authenticated &&
      props.videoOnDemand.videoOn
    ) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        setErrorMessage("The video has ended!");
        setModalOpen(true);
        setTimeout(() => props.loginDetails.keycloak.logout(), 5000);
      }
    }
  }, [
    loginContext.sessionRestrictor,
    props.loginDetails.authenticated,
    props.videoOnDemand.videoOn,
    seconds,
  ]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const redeemAPi = async (e) => {
    e.preventDefault();
    const { keycloak, user } = props.loginDetails;
    const { sessionId } = keycloak;
    const timestamp = +new Date();
    const useragent = deviceDetect();
    let session = { sessionId, timestamp, useragent };

    let passId = props.match.params.id;
    if (props.loginDetails.user.userid && passId) {
      let res = await props.fetchVideoOnRedeem(
        props.loginDetails.user,
        session,
        passId
      );
      if (res.count) {
        props.changeVideoOnDemandValues("videoOn", true);
      } else if (res.message) {
        setErrorMessage(res.message);
        setModalOpen(true);
      } else {
        setErrorMessage("Unable to process your request");
        setModalOpen(true);
      }
    }
  };

  return (
    <>
      <div className="row vodContainer">
        <div className="container">
          <div className="wholediv">
            <div className="videodiv voddiv disablechat">
              {!loginContext.sessionRestrictor &&
                props.loginDetails.authenticated &&
                props.videoOnDemand.videoOn &&
                !errorMessage && (
                  <SisticLivePlayerAlt
                    className="live-player"
                    playerItems={{
                      src: "https://view.vzaar.com/23214104/player",
                      stopChat: true,
                    }}
                  />
                )}
            </div>
            {props.loginDetails.authenticated === null ||
            props.loginDetails.authenticated === false ||
            props.videoOnDemand.videoOn === false ? (
              <div className="redeemmobchatalt"></div>
            ) : (
              ""
            )}
            {loginContext.sessionRestrictor ||
            props.loginDetails.authenticated === null ||
            props.loginDetails.authenticated === false ||
            props.videoOnDemand.videoOn === false ? (
              <div className="wrap_div ">
                <div>
                  <Text as="h6" className="redeem_title">
                    <strong>
                      {
                        "JJ Lin 「SANCTUARY FINALE」 Virtual Concert Single View Broadcast "
                      }
                      <br />
                      {"JJ 林俊傑《聖所 FINALE》終點站 線上演唱會限定轉播"}
                    </strong>
                  </Text>
                  <Text as="h6" className="redeem_title_paragraph">
                    <p>
                      You may choose to view the full JJ Lin「Sanctuary
                      Finale」Virtual Concert Single View Broadcast at a time of
                      your convenience between 19 July 2021, 1000hrs (+8 GMT) to
                      2 August 2021 2359hrs (+8 GMT).
                    </p>
                    <br />
                    <p>
                      請在 2021 年 7 月 19 日（星期一） 10:00 至 2021 年 8 月 2
                      日（星期一） 23:59 之間 (+8GMT) 的任何時間
                      返回此獨特的網址鏈接觀看錄線上演唱會限定轉播。
                    </p>
                  </Text>
                </div>
                {/* <div className="chinnese_title">
                  <Text as="h6" className="redeem_title">
                    <strong>
                      {"JJ 林俊傑《聖所 FINALE》終點站 線上演唱會"}
                    </strong>
                  </Text>
                  <Text as="h6" className="redeem_title_paragraph">
                    <p>
                      請在 2021 年 7 月 19 日（星期一） 10:00 至 2021 年 8 月 2
                      日（星期一） 23:59 之間 (+8GMT) 的任何時間
                      返回此獨特的網址連結登觀看錄線上演唱會。
                    </p>
                  </Text>
                </div> */}
                <div>
                  <a
                    href="/FAQ"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Button primary className="video_btn">
                      View Full FAQs 常見問題
                    </Button>
                  </a>
                </div>
                {/* <div>
                  <Button
                    primary
                    className="video_btn"
                    disabled={
                      props.loginDetails.authenticated === null ||
                      props.loginDetails.authenticated === false
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      redeemAPi(e);
                    }}
                  >
                    proceed to watch
                  </Button>
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="synopiscontainer">
          <div className="synopisdiv">
            <div className="redeemsynopissection">
              <Text as="h6" className="festure-presenter">
                {"[NOTICE] "}
              </Text>
              <br />
              <Text as="p">
                <ul>
                  <li>
                    You must complete the viewing in one seating within 125
                    minutes.
                  </li>
                  <li> You may refresh the browser during this time.</li>
                  <li>
                    DO NOT switch the browser or swap devices after log in.
                  </li>
                  <li>
                    To ensure compliance to copyright requirements, No
                    playbacks, scrubbing or pauses will be allowed.
                  </li>
                </ul>
                <br />
                {
                  "Any form of recording, screenshot and/or mirroring is strictly not allowed. Reproduction and distribution of content without the copyright owner's consent constitutes an infringement of copyright. If you are caught illegally streaming this content, you could be served legal sanction for copyright violation."
                }
                <br />
                {
                  "If you encounter any technical difficulties at any stage, please contact us feedback@sistic.com.sg or call us at +6348 5555 for assistance."
                }
              </Text>
              <br />
              <Text as="h6" className="festure-presenter">
                {"[通知]"}
              </Text>
              <br />
              <Text as="p">
                <ul>
                  <li>登入后觀看鏈接有效期為125分鐘。</li>
                  <li>您可以在這段時間內刷新瀏覽器。</li>
                  <li>觀看期間，您將無法轉換瀏覽器或更換您的播放儀器。</li>
                  <li>由於版權合規，您也不能回放、拖動或暫停播放。</li>
                </ul>
                <br />
                {
                  "演出嚴禁屏幕攝像、錄製或鏡像模式。未經著作權人的同意擅自發布、加工或重複使用內容的行為屬於侵權行為。如果被發現非法播放或任何形式分享此演出，您將會受到侵犯著作權相關的法律制裁。"
                }
                <br />
                {
                  "如您有疑問，請電郵 feedback@sistic.com.sg 或撥打 +65 6348 5555 與 SISTIC 客戶服務聯繫。"
                }
              </Text>
              {/* <Text as="h6" className="festure-presenter">
                {"JJ Lin 「SANCTUARY FINALE」 Virtual Concert Encore Stream"}
              </Text>
              <br />
              <Text as="h6" className="festure-presenter">
                {" When Ready to Watch "}
              </Text>
              <br />
              <Text as="p">
                <ul>
                  <li>
                    Please note after you log in, you must click "I Agree" to
                    access the stream.{" "}
                    <strong>
                      The ticket will be deemed as utilised only after you click
                      the "I Agree" button.
                    </strong>
                  </li>
                  <li>
                    After log in, once the link is activated, you must complete
                    the viewing in one seating within 125 minutes.
                  </li>
                  <li>
                    <strong>
                      <span className="red_font">
                        DO NOT refresh the browser during this time.
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <span className="red_font">
                        DO NOT switch the browser or swap devices after log in.
                      </span>
                    </strong>
                  </li>
                  <li>
                    No playbacks, scrubbing or pauses will be allowed. This is
                    to ensure compliance to copyright requirements.
                  </li>
                </ul>
                <br />
                {
                  "Please proceed to log in ONLY when you are ready to view, as you can only view the concert once. You may choose to view the full concert at a time of your convenience before 2 August 2021, 2359h (+8 GMT). "
                }
                <br />
              </Text>
              <Text as="h6" className="festure-presenter">
                {" Unable to Watch  "}
              </Text>
              <br />
              <Text as="p">
                {
                  "Ticket holders who are not able to watch the virtual concert during the stated period may share the link with a friend. Reselling your ticket is strictly prohibited and your link would be forfeited should you do so. "
                }
                <br />
                <br />
                {
                  "Any form of recording, screenshot and/or mirroring is strictly not allowed. Reproduction and distribution of content without the copyright owner's consent constitutes an infringement of copyright. If you are caught illegally streaming this content, you could be served legal sanction for copyright violation."
                }
                <br />
                <br />
                {
                  "If you encounter any technical difficulties at any stage, please contact us feedback@sistic.com.sg or call us at +6348 5555 for assistance."
                }
              </Text>
              <br />
              <Text as="h6" className="festure-presenter">
                {"JJ 林俊傑《聖所 FINALE》終點站 線上演唱會轉播"}
                <br />
                <br />
                {" 觀看期間"}
              </Text>
              <br />
              <Text as="p">
                <ul>
                  <li>
                    一旦您點擊了您的獨特鏈接登入觀看平台後，您必须点击 “I
                    Agree"（我同意），鏈接就算是採用了。
                  </li>
                  <li>獨特鏈接僅提供一次性的觀看，敬請留意。</li>
                  <li>
                    登入后觀看鏈接有效期為125分鐘。
                    <span className="red_font">請不要刷新瀏覽器。</span>
                  </li>
                  <li>
                    {" "}
                    觀看期間，由於版權合規，
                    <span className="red_font">
                      您將無法刷新、轉換瀏覽器或更換您的播放儀器。您也不能回放、拖動或暫停播放。
                    </span>
                  </li>
                  <li>
                    因此，請確認您已準備好可以觀看整場演出後才用您的獨特鏈接登入播放平台。一人獨特鏈接只允許一次觀看權。
                  </li>
                  <li>
                    您可以在有效期間的任何時段觀看，但請務必於 2021
                    年8月2日，晚上11:59 前把演出看完。
                  </li>
                </ul>
              </Text>
              <Text as="h6" className="festure-presenter">
                {"無法觀看"}
              </Text>
              <br />
              <Text as="p">
                {
                  "無法在指定日期觀看演出的持票人可以把鏈接分享給家人或朋友觀看。轉賣您的獨特鏈接是嚴厲禁止的。若被發現，您的獨特鏈接可能會將被取消。"
                }
                <br />
                {
                  "如您有疑問，請電郵 feedback@sistic.com.sg 或撥打 +65 6348 5555 與 SISTIC 客戶服務聯繫。"
                }
              </Text> */}
            </div>

            <div className="mobsynopisdiv">
              <div className="redeemsynopissection">
                <Text as="h6" className="synopsis">
                  Short Introduction
                </Text>
                <Text as="p">
                  {ReactHtmlParser(
                    `The grand finale to JJ Lin’s Sanctuary World Tour will be streamed live to a global audience on 6 June 2021, 8pm (GMT +8). \n\n A year in the making, this highly anticipated online concert will feature curated content, customised stage design and brand new costumes, creating an immersive and interactive performance to bring you an audio-visual experience like never before. \n\n<span style='color:black;'>歷經一年的規劃與籌備，從內容、歌曲、音樂編排的重整到舞台設計、視覺創意等技術研討，以《聖所》的初心為出發，將震撼的舞台與表演，毫無保留地再現，打造一場專屬於線上觀賞的獨特體驗。前所未有的互動形式，讓每一個你都能身臨其境，與JJ緊緊相扣。 </span> \n\n <span style='color:black;'>6 月6 日，晚上 8 點 (GMT +8)，不局限於距離、地點，首度敞開線上聖所，讓我們相約終點站，全球同步悸動！</span> `.replace(
                      /(?:\r\n|\r|\n)/g,
                      "<br />"
                    )
                  )}
                </Text>
              </div>
            </div>
            <div className="chatsection">
              <div className="videoDetailFeature">
                <Text as="h4" className="artist">
                  <b>FEATURE ARTIST</b>
                </Text>
                <Text as="h6" className="festure-presenter">
                  {ReactHtmlParser(
                    `About JJ Lin`.replace(/(?:\r\n|\r|\n)/g, "<br />")
                  )}
                </Text>
                <Text as="p">
                  {ReactHtmlParser(
                    `JJ Lin is a singer-songwriter/producer who emerged to award-winning success with his 2003 debut, Music Voyager. His popularity soared throughout Asia with his\nconsistent release of award-winning chart toppers throughout his illustrious career of 17 years, making him one of the most sought-after artistes in the region. \n\n Coined “The Walking CD” by netizens, JJ has performed to 1.6 million spectators spanning 125 tour stops worldwide, and accumulated more than 120 billion streams on music platforms such as QQ Music, Spotify, Apple Music, KKBOX and etc to date. \n\n A versatile performer, JJ’s portfolio of exciting music collaborators include Jason Mraz, Jung Yong-Hwa of CNBLUE, MAYDAY, Luis Fonsi, Ayumi Hamasaki, G.E.M,\n Jam Hsiao, Hiroshi Fujiwara, Steve Aoki, Jackson Wang and Anne Marie. In October last year, a record of 17 million people tuned in for JJ’s new album live streaming concert, a testament to his popularity.\n\n JJ is managed by JFJ Productions worldwide, and represented by United Talents Agency in USA and UK.`.replace(
                      /(?:\r\n|\r|\n)/g,
                      "<br />"
                    )
                  )}
                </Text>
              </div>
            </div>
          </div>
          <div className="websynopisdiv">
            <div className="redeemsynopissection">
              <Text as="h6" className="synopsis">
                Short Introduction
              </Text>
              <Text as="p">
                {ReactHtmlParser(
                  `The grand finale to JJ Lin’s Sanctuary World Tour will be streamed live to a global audience on 6 June 2021, 8pm (GMT +8). \n\n A year in the making, this highly anticipated online concert will feature curated content, customised stage design and brand new costumes, creating an immersive and interactive performance to bring you an audio-visual experience like never before. \n\n<span style='color:black;'>歷經一年的規劃與籌備，從內容、歌曲、音樂編排的重整到舞台設計、視覺創意等技術研討，以《聖所》的初心為出發，將震撼的舞台與表演，毫無保留地再現，打造一場專屬於線上觀賞的獨特體驗。前所未有的互動形式，讓每一個你都能身臨其境，與JJ緊緊相扣。 </span> \n\n <span style='color:black;'>6 月6 日，晚上 8 點 (GMT +8)，不局限於距離、地點，首度敞開線上聖所，讓我們相約終點站，全球同步悸動！</span> `.replace(
                    /(?:\r\n|\r|\n)/g,
                    "<br />"
                  )
                )}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <VideoRestriction
        isOpen={modalOpen}
        errorMessage={errorMessage}
        onClose={closeModal}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.Login,
    transactionDetails: state.Transaction,
    programmeDetails: state.Programmes,
    videoOnDemand: state.VideoOnDemand,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  changeProgrammesValues: (f, e) =>
    dispatch(progrmmeActions.changeProgrammesValues(f, e)),
  changeVideoOnDemandValues: (f, e) =>
    dispatch(VideoOnDemand.changeVideoOnDemandValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
  fetchProgrammeDetail: (id) =>
    dispatch(progrmmeActions.fetchProgrammeDetail(id)),
  fetchVideoOnDemandDetail: (progammeId) =>
    dispatch(VideoOnDemand.fetchVideoOnDemandDetail(progammeId)),
  fetchVideoOnRedeem: (user, programmeId) =>
    dispatch(VideoOnDemand.fetchVideoOnRedeem(user, programmeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VODRedeem);
