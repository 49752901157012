import React, { useEffect, useState } from "react";
import {
  Shell,
  Carousel,
  Card,
  Text,
  Button,
  Scheduler,
  LiveIcon,
  RightArrowIcon,
  Dropdown,
} from "@StixNext/mojito-ui";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../actions/Programmes";
import * as Images from "./common/watch-free";
const sortByProperty = (property) => {
  return (a, b) => {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;
    return 0;
  };
};
const options = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const Livestream = (props) => {
  const {
    programmesList = [],
    fetchProgrammes,
    history,
    dateSelected,
    setDateSelected,
  } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };
  let defultChannel = [
    { channelName: "Channel 1", channelId: 1 },
    { channelName: "Channel 2", channelId: 2 },
    { channelName: "Channel 3", channelId: 3 },
  ];
  const [channel, setChannel] = useState(defultChannel);
  const [mobchannel, setMobChannel] = useState(defultChannel);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(defultChannel[0]);
  const [scheduleData, setScheduleData] = useState(programmesList);
  const [slidesEvents, setSlidesEvents] = useState([]);
  useEffect(() => {
    fetchProgrammes();
  }, []);
  useEffect(() => {
    handleChannelView();
  }, [selectedChannel]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  const handleChannelView = () => {
    let filterChannel = defultChannel.filter(
      (item) => item.channelId == selectedChannel
    );
    let defultValue = defultChannel.length > 0 ? defultChannel[0] : {};
    filterChannel = filterChannel.length == 0 ? [defultValue] : filterChannel;

    setMobChannel(filterChannel);
  };

  let timeLine = [
    { time: "10:00", endTime: "1100", startTime: "1000" },
    { time: "11:00", endTime: "1200", startTime: "1100" },
    { time: "12:00", endTime: "1300", startTime: "1200" },
    { time: "13:00", endTime: "1400", startTime: "1300" },
    { time: "14:00", endTime: "1500", startTime: "1400" },
    { time: "15:00", endTime: "1600", startTime: "1500" },
    { time: "16:00", endTime: "1700", startTime: "1600" },
    { time: "17:00", endTime: "1800", startTime: "1700" },
    { time: "18:00", endTime: "1900", startTime: "1800" },
    { time: "19:00", endTime: "2000", startTime: "1900" },
    { time: "20:00", endTime: "2100", startTime: "2000" },
    { time: "21:00", endTime: "2200", startTime: "2100" },
    { time: "22:00", endTime: "2300", startTime: "2200" },
    { time: "23:00", endTime: "2400", startTime: "2300" },
  ];
  const setScheduleFilter = (date) => {
    let data = programmesList
      .sort(sortByProperty("startTime"))
      .filter((programme) => programme.date == dateSelected);
    setScheduleData(data);
    setSlidesEvents(data);
  };
  useEffect(() => {
    let dates = [];
    let todaySlides = [];
    let today = new Date().toLocaleDateString();
    let selectDate = null;
    programmesList.map((programmes) => {
      programmes["buttonEnabled"] = programmes.QAFlag ? true : false;
      dates.push(programmes.date);
      let programmesDate = new Date(programmes.date).toLocaleDateString();
      if (today == programmesDate) {
        let current = moment(programmes.endDateTime).diff(moment(), "minute");
        if (current > 0) {
          todaySlides.push(programmes);
        }
        selectDate = programmes.date;
      }
    });
    setSlidesEvents(todaySlides);
    dates.push(dateSelected);
    let uniqueDates = dates.sort().filter((v, i, a) => a.indexOf(v) === i);
    // uniqueDates=uniqueDates.sort()
    uniqueDates = uniqueDates.map((date) => {
      const dateObj = new Date(date);
      const dateString = dateObj.toLocaleDateString(undefined, options);
      return { text: dateString, value: date };
    });
    setDateOptions(uniqueDates);
    let data = programmesList
      .sort(sortByProperty("startTime"))
      .filter((programme) => programme.date == dateSelected);
    setScheduleData(data);
  }, [programmesList]);
  useEffect(() => {
    setScheduleFilter(dateSelected);
  }, [dateSelected]);
  const handleClick = (eventId) => history.push(`/livevideo/${eventId}`);
  return (
    <Shell.Body className={ "css-livenow"}>
      {slidesEvents.length > 0 && (
        <Grid>
          <Row>
            <Column xl={12}>
              <Carousel
                autoPlay={false}
                interval={5000}
                slidesPresented={windowWidth > 760 ? 3 : 1}
                Indictor={!(windowWidth <= 760)}
                mobileView={windowWidth <= 760}
                className={"carousel"}
              >
                {slidesEvents
                  .sort(sortByProperty("startTime"))
                  .map((slide, index) => {
                    let images = Images[
                      `Image_100${slide.id
                        .toString()
                        .slice(
                          slide.id.toString().length - 1,
                          slide.id.toString().length
                        )}`
                    ]
                      ? Images[
                          `Image_100${slide.id
                            .toString()
                            .slice(
                              slide.id.toString().length - 1,
                              slide.id.toString().length
                            )}`
                        ]
                      : Images[`defaultImage`];
                    return (
                      <Card
                        key={index}
                        className={"video-slides"}
                        onClick={() => handleClick(slide?.id)}
                      >
                        <img src={images} alt="" />
                        <div>
                          <Text as="div" className={"title-tiles vod-tiles"}>
                            {slide?.title}
                          </Text>
                          <Text as="p" className="style-p">
                            {"Duration: " + slide?.duration + " mins"} <br />
                            {"Uploaded on: " + slide?.updatedDate} <br />
                          </Text>
                        </div>
                      </Card>
                    );
                  })}
              </Carousel>
            </Column>
          </Row>
        </Grid>
      )}
      <Grid>
        <Row className="scheduler-class">
        <Column xl={12}>

         
          <div  id="dropdown_123" style={{display:'flex'}}>
          <h6
            style={{ paddingTop: 5, margin: "0px 15px 15px 15px" }}
            className="schedule-title"
          >
            SCHEDULE <RightArrowIcon style={{ mariginLeft: 5 }} />
          </h6>
            <Dropdown
              id="dropdown"
              options={dateOptions}
              mulitple={false}
              Width={250}
              value={dateSelected}
              placeholder="Select a Date"
              onChange={(item) => {
                console.log("onChange", item);
                setDateSelected(item.value);
              }}
            />
            <Dropdown
              id="dropdown_channel"
              options={defultChannel.map((channel, index) => {
                return {
                  text: channel.channelName,
                  value: channel.channelId,
                };
              })}
              mulitple={false}
              value={selectedChannel}
              placeholder="Select a channel"
              onChange={(item) => setSelectedChannel(item.value)}
            />
          </div>
          {/* <div style={{ display: "flex" }}>
            <select
              className={"dropdwon-scheduler"}
              value={dateSelected}
              onChange={(e) => setDateSelected(e.target.value)}
            >
              {dateOptions.map((date) => {
                let event = new Date(date);
                const options = {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                };
                return (
                  <option value={date}>
                    {event.toLocaleDateString(undefined, options)}
                  </option>
                );
              })}
            </select>
            <select
              className={"channel-select"}
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e.target.value)}
            >
              {defultChannel.map((item, index) => (
                <option value={item.channelId}>{item.channelName}</option>
              ))}
            </select>
          </div> */}
          </Column>
        </Row>

        <Row>
        <Column xl={12}>

          <Scheduler
            className="web_schedule"
            scheduleData={scheduleData}
            timeLine={timeLine}
            channel={channel}
            eventOnClick={handleClick}
            titleKey={"title"}
            subTitleKey={"featuredPresenters"}
            defultTextButton={"Live Q&A "}
          ></Scheduler>
          <Scheduler
            className="mob_schedule"
            scheduleData={scheduleData}
            timeLine={timeLine}
            channel={mobchannel}
            eventOnClick={handleClick}
            titleKey={"title"}
            subTitleKey={"featuredPresenters"}
            defultTextButton={"Live Q&A "}
          ></Scheduler>
          </Column>
        </Row>
      </Grid>
    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  programmesList: state.Programmes.programmesList,
  dateSelected: state.Programmes.dateSelected,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProgrammes: () => dispatch(actions.fetchProgrammes()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Livestream);
