import React, { useEffect } from "react";

import { connect } from "react-redux";
import {
  Shell,
  Stage,
  FeatureArtists,
  Video,
  Text,
  Button,
  BouncerContextHook,
  Card,
} from "@StixNext/mojito-ui";
import useCommonI18n from "../utils/i18nHelpers";
import HANS from "../assets/images/Logo1.png";
import LAN from "../assets/images/Logo2.png";
import JOSHUA from "../assets/images/Logo3.png";
import ANDREW from "../assets/images/Logo4.png";
import MASTER from "../assets/images/Logo5.png";
import KRISFLYER from "../assets/images/Logo6.png";
import PRIVILEGELOGO from "../assets/images/Logo7.png";
import banner from "../assets/images/banner_stage.jpg";
import banner_mob from "../assets/images/banner_mob.jpg";
import main1 from "../assets/images/stage_nxt.png";
import schedules from "../assets/images/overview_schedule.png";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import Bravissimo from "../assets/images/bravissimo.jpg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import ticket from "../assets/images/ticket.svg";
import passport from "../assets/images/passport.svg";
import power from "../assets/images/power.svg";
import poster from "../assets/images/poster.svg";
import boxset from "../assets/images/boxset.svg";
import totebag from "../assets/images/totebag.svg";

function Overview(props) {
  const value = BouncerContextHook();
  // let onClose = () => {
  //   props.changeTransactionValues("productPrice", 0);
  //   props.changeTransactionValues("showDropinComponent", false);
  //   props.changeTransactionValues("transactionStatus", 1);
  //   props.changeTransactionValues("checkconsent", false);
  //   props.changeTransactionValues("isOpen", false);
  //   localStorage.setItem("buttonClicked", false);
  // };
  let getDegitalPass = async (e) => {
    localStorage.setItem("buttonClicked", true);
    localStorage.setItem("logout", false);
    setTimeout(() => {
      if (!props.loginDetails.authenticated) {
        value.loginKeycloak();
      } else {
        props.fetchTransaction(
          props.loginDetails.user,
          localStorage.getItem("buttonClicked")
        );
      }
    }, 1000);
  };

  useEffect(() => {
    if (localStorage.getItem("grouppass") == "true") {
      props.changeTransactionValues("hideBuyButton", true);
      if (localStorage.getItem("logout") == "true") {
        props.changeTransactionValues("transactionStatus", 5);
        props.changeTransactionValues("isOpen", true);
      }
      localStorage.setItem("grouppass", false);
    }
    localStorage.setItem("grouppasscompleted", false);
  }, []);

  const { translate } = useCommonI18n();

  // const handle_click = no => {
  //   const urls = [
  //     "https://academyofrock.co/",
  //     "https://xctuality.com/",
  //     "https://www.sistic.com.sg/",
  //     "https://www.facebook.com/musicanddramacompany/",
  //     "https://www.crossratioent.com/",
  //     "https://www.facebook.com/MoCaYN/",
  //   ];
  //   window.open(urls[no], "_blank")
  // };

  return (
    <>
      <Shell.Body className="overview_body_cnt">
        <Grid>
          <Row>
            <Column>
              <div className={"justify"}>
                <Stage
                  className={"stage-class"}
                  items={{
                    image: main1,
                    title: "ABOUT THE FESTIVAL",
                    description: translate("overview.stage.discription"),
                    price: "S$12.00",
                    priceContent: translate("overview.stage.priceContent"),
                  }}
                ></Stage>{" "}
              </div>
            </Column>
          </Row>
          {/* <Row>
            <Column>
              <div>
                <img src={banner} alt="icon" className="mike_img" />
                <img src={banner_mob} alt="icon" className="mike_mob_img" />
              </div>
            </Column>
          </Row> */}
        </Grid>
      </Shell.Body>
      <div className="highlight-class highlight-class-color">
        <Grid>
          <Row>
            <Column>
              <Text as="div" className="highlight-title">
                TRAILER
              </Text>

              <div className="trailerDiv">
                <iframe
                  src={
                    "https://iframe.dacast.com/vod/e7a93a76-feb5-e046-4232-16697da54fb2/a37f0b22-8782-9d10-69cf-3b814e319dee"
                  }
                  width="100%"
                  height="100%"
                  frameborder="0"
                  scrolling="no"
                  allow="autoplay"
                  autoplay="true"
                  allowFullScreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  oallowfullscreen="true"
                  msallowfullscreen="true"
                ></iframe>
              </div>
            </Column>
          </Row>
        </Grid>
      </div>
      <div className="highlight-class ticket-tier-class">
        <Grid>
          <Row>
            <Column>
              <Text as="div" className="highlight-title">
                SCHEDULES
              </Text>

              {/* <Video
                className={"video-class"}
                items={{
                  url: "https://www.youtube.com/watch?v=aUoWnBJo91o",
                  thumbnail: Bravissimo,
                }}
              ></Video> */}
            </Column>
          </Row>
          <Row>
            <Column className="schedule_column">
              <img
                src={schedules}
                className="schedule_img"
                style={{ width: "100%" }}
              />
              {/* <div className="schedule_container">
                <Row style={{ display: "flex", flexFlow: "row" }}>
                  <Column xl={6} className="left_div">
                    <div className="left_box">
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "column",
                          padding: "25px",
                          paddingTop: "20px",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "row",
                            alignItems: "center",
                            columnGap: "20px",
                          }}
                        >
                          <span></span>
                          <span
                            style={{
                              display: "flex",
                              flexFlow: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            to
                          </span>
                          <span></span>
                        </div>
                        <Text as="h5" className="purple_head">
                          REGISTRATION <br />
                          (Open Call)
                        </Text>
                        <Text as="p" className="regis_txt">
                          SSF Festival’s NXT – A Virtual Music Event is a
                          platform for youths in Singapore by showcasing their
                          performing talents.
                        </Text>
                      </div>
                    </div>
                    <div className="left_connection"></div>
                  </Column>
                  <Column xl={6} className="right_div">
                    <div className="right_connection"></div>
                    <div className="right_box">
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "column",
                          padding: "25px",
                          paddingTop: "20px",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "row",
                            alignItems: "center",
                            columnGap: "20px",
                          }}
                        >
                          <span></span>
                          <span
                            style={{
                              display: "flex",
                              flexFlow: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            to
                          </span>
                          <span></span>
                        </div>
                        <Text as="h5" className="blue_head">
                          PUBLIC VOTING FOR YOUR <br />
                          FAVOURITE GROUP
                        </Text>
                      </div>
                    </div>
                  </Column>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexFlow: "row",
                    paddingTop: "50px",
                  }}
                >
                  <Column xl={6} className="jul_aug_col">
                    <Row>
                      <Column className="lastjul_col">
                        <div className="lastjul_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="pink_head">
                              3-4MIN AUDITION <br />
                              VIDEO CLOSING DATE OF <br />
                              SUBMISSION
                            </Text>
                            <Text as="p" className="regis_txt">
                              Participating groups to express their creativity
                              and imagination skills by presenting their
                              performance in this 3-4min video to be submitted
                              for audition and voting purposes.
                            </Text>
                          </div>
                        </div>
                        <div className="lastjul_connection"></div>
                      </Column>
                    </Row>

                    <Row style={{ paddingTop: "30px" }}>
                      <Column className="sev_aug_div">
                        <div className="sev_aug_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="purple_head">
                              RESULTS – TOP 9 Groups
                            </Text>
                          </div>
                        </div>
                        <div className="sev_aug_connection"></div>
                      </Column>
                    </Row>
                  </Column>
                  <Column xl={6} className="one_aug_div">
                    <div className="one_aug_connection"></div>
                    <div className="one_aug_box">
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "column",
                          padding: "25px",
                          paddingTop: "20px",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "row",
                            alignItems: "center",
                            columnGap: "20px",
                          }}
                        >
                          <span></span>
                          <span
                            style={{
                              display: "flex",
                              flexFlow: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            to
                          </span>
                          <span></span>
                        </div>
                        <Text as="h5" className="green_head">
                          AUDITION AND SELECTION
                        </Text>
                        <Text as="p" className="regis_txt">
                          Audition by your video submission link for selection
                          purposes. Selection is by 40% general public voting,
                          and 60% by panellists from industry professionals
                          selection with criteria as follows: <br />
                          [a] Tone (Sound quality of group/performance); <br />
                          [b] Rhythm (Tightness of ensemble/team work);
                          <br /> [c] Expression
                          (Musicianship/preparedness/attitude); <br />
                          [d] Interpretation (Stylistic element/presentation);{" "}
                          <br />
                          [e] Showmanship (Entertainment value/stage presence).
                        </Text>
                        <Text as="h6" className="bold_head">
                          Top 9 youth groups will be selected.
                        </Text>
                      </div>
                    </div>
                  </Column>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexFlow: "row",
                    alignItems: "start",
                  }}
                >
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ paddingTop: "43px" }}>
                      <Column className="aug_sep_col">
                        <div className="aug_sep_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="pink_head">
                              MASTERCLASSES BY 3<br />
                              COMPOSERS
                            </Text>
                            <Text as="p" className="regis_txt">
                              Each 2hour masterclasses has 3-part components:
                              <br />
                              <ul style={{ paddingLeft: "18px" }}>
                                <li>
                                  Composer’s presentation on the creative
                                  process of the landmark pieces
                                </li>
                                <li>
                                  Selected group participants to bring choice
                                  work to consult composer’s expertise on their
                                  works
                                </li>
                                <li>
                                  Q & A; also to deal with participants’ topic
                                  of interest regarding composing
                                </li>
                              </ul>
                            </Text>
                            <Text as="h6" className="bold_head">
                              Composers
                            </Text>
                            <Text as="p" className="regis_txt">
                              Lee Jin Jun, Syafiqah’ Adha Sallehin, and Terence
                              Wong
                            </Text>
                          </div>
                        </div>
                        <div className="aug_sep_connection"></div>
                      </Column>
                    </Row>

                    <Row style={{ paddingTop: "40px" }}>
                      <Column className="sep_div">
                        <div className="sep_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="purple_head">
                              REHEARSAL (PART 1) WITH <br />
                              MENTORS
                            </Text>
                          </div>
                        </div>
                        <div className="sep_connection"></div>
                      </Column>
                    </Row>
                  </Column>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ paddingTop: "30px" }}>
                      <Column className="sixteen_aug_div">
                        <div className="sixteen_aug_connection"></div>
                        <div className="sixteen_aug_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="blue_head">
                              DIALOGUE SESSION
                            </Text>
                            <Text as="p" className="regis_txt">
                              Each 2hour masterclasses has 3-part
                              components:Organisers and Artistic Director
                              briefing to the selected top 9 youth groups
                              <br />
                              <ul style={{ paddingLeft: "18px" }}>
                                <li>About NXT – A Virtual Music Event</li>
                                <li>Let’s talk about Schedule</li>
                                <li>Q & A</li>
                              </ul>
                            </Text>
                            <Text as="p" className="regis_txt">
                              16 of Aug 2021 – 7:30pm on ZOOM
                            </Text>
                          </div>
                        </div>
                      </Column>
                    </Row>

                    <Row style={{ paddingTop: "40px" }}>
                      <Column className="sep_nov_div">
                        <div className="sep_nov_connection"></div>
                        <div className="sep_nov_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="green_head">
                              WORKING WITH MENTORS
                            </Text>
                            <Text as="p" className="regis_txt">
                              Selected 9 groups can use their submission piece
                              or another piece to receive mentorship from these
                              industry professionals, who will provide feedback
                              and guide to help them improve on their
                              performance skills and also further develop better
                              delivery.
                            </Text>
                            <Text as="h6" className="bold_head">
                              Mentors
                            </Text>
                            <Text as="p" className="regis_txt">
                              Tama Goh, Fabian Lim, Colin Yong, Felix Phang,
                              Sebastian Ho, Rit Xu, and Govin Tan
                            </Text>
                          </div>
                        </div>
                      </Column>
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <Column xl={6}></Column>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ paddingTop: "40px" }}>
                      <Column className="fivesep_div">
                        <div className="fivesep_connection"></div>
                        <div className="fivesep_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="blue_head">
                              MENTORSHIP/CLINICS
                            </Text>
                            <Text as="p" className="regis_txt">
                              2hour mentorship covering:
                              <br />
                              <ul
                                style={{
                                  paddingLeft: "18px",
                                  listStyle: "none",
                                }}
                              >
                                <li>
                                  (a) Mentors panelists’ sharing professional
                                  work
                                </li>
                                <li>
                                  (b) Musician’s Aptitude:
                                  <ul
                                    style={{
                                      paddingLeft: "18px",
                                      listStyle: "none",
                                    }}
                                  >
                                    <li>
                                      (i) Know how of being a music leader
                                    </li>

                                    <li>
                                      (ii) Working with other music leaders
                                    </li>

                                    <li>
                                      (iii) Working with and care to music
                                      members
                                    </li>

                                    <li>
                                      (iv) How to equip with knowledge-based and
                                      work ethics
                                    </li>
                                  </ul>
                                </li>
                                <li>(c) Discussion and Q & A</li>
                              </ul>
                            </Text>
                          </div>
                        </div>
                      </Column>
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ marginTop: "-21px" }}>
                      <Column className="one_nov_col">
                        <div className="one_nov_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="pink_head">
                              INDIVIDUAL SOUND RECORDING
                            </Text>
                          </div>
                        </div>
                        <div className="one_nov_connection"></div>
                      </Column>
                    </Row>
                  </Column>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ paddingTop: "15px" }}>
                      <Column className="eight_nov_div">
                        <div className="eight_nov_connection"></div>
                        <div className="eight_nov_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="green_head">
                              REHEARSAL (PART 2) WITH <br />
                              MENTORS
                            </Text>
                          </div>
                        </div>
                      </Column>
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ paddingTop: "20px" }}>
                      <Column className="fourteen_nov_col">
                        <div className="fourteen_nov_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="purple_head">
                              9 GROUPS RECORDING
                            </Text>
                            <Text as="p" className="regis_txt">
                              On Site: Infinite Studio
                              <br />
                              <ul
                                style={{
                                  paddingLeft: "18px",
                                  listStyle: "none",
                                }}
                              >
                                <li>(1) Landmark pieces</li>
                                <li>(2) Group & Mentor</li>
                                <li>(3) Finale Piece</li>
                              </ul>
                            </Text>
                          </div>
                        </div>
                        <div className="fourteen_nov_connection"></div>
                      </Column>
                    </Row>
                  </Column>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ paddingTop: "50px" }}>
                      <Column className="twenty_nov_div">
                        <div className="twenty_nov_connection"></div>
                        <div className="twenty_nov_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="blue_head">
                              PROFESSIONAL ARTISTS/SPECIAL <br />
                              NEEDS FINAL RECORDING
                            </Text>
                          </div>
                        </div>
                      </Column>
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ paddingTop: "40px" }}>
                      <Column className="seven_dec_col">
                        <div className="seven_dec_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="pink_head">
                              COMPLETED VIDEO EDIT AND SOUND BALANCE
                            </Text>
                            <Text as="p" className="regis_txt">
                              Post-production begins immediately after
                              recording.
                            </Text>
                          </div>
                        </div>
                        <div className="seven_dec_connection"></div>
                      </Column>
                    </Row>
                  </Column>
                  <Column xl={6}></Column>
                </Row>
                <Row>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ paddingTop: "20px" }}>
                      <Column className="eightteen_feb_col">
                        <div className="eightteen_feb_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="purple_head">
                              SSF’s 20 Anniversary:NXT - <br />
                              A Virtual Music Event
                              <br />
                              ON DEMAND viewing
                            </Text>
                          </div>
                        </div>
                        <div className="eightteen_feb_connection"></div>
                      </Column>
                    </Row>
                  </Column>
                  <Column xl={6} className="jul_aug_col">
                    <Row style={{ marginTop: "-16px" }}>
                      <Column className="eighteen_dec_div">
                        <div className="eighteen_dec_connection"></div>
                        <div className="eighteen_dec_box">
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              padding: "25px",
                              paddingTop: "20px",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row",
                                alignItems: "center",
                                columnGap: "20px",
                              }}
                            >
                              <span></span>
                              <span
                                style={{
                                  display: "flex",
                                  flexFlow: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                to
                              </span>
                              <span></span>
                            </div>
                            <Text as="h5" className="green_head">
                              PREMIERE of SSF’s 20 <br />
                              Anniversary: NXT – A Virtual Music
                              <br />
                              Event @ 8 PM
                            </Text>
                          </div>
                        </div>
                      </Column>
                    </Row>
                  </Column>
                </Row>
              </div> */}
            </Column>
          </Row>
        </Grid>
      </div>
      <div className="highlight-class sponsor-class">
        <Grid>
          <Row>
            <Column xl={8} lg={12} md={12} sm={12}>
              <Row>
                <Column>
                  <Text as="div" className="highlight-title">
                    OUR SUPPORTING PARTNERS
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
                  <a href="https://academyofrock.co/" target="_blank">
                    <div className="image_div">
                      <img src={HANS} className="spono_img" />
                    </div>
                  </a>
                </Column>
                <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
                  <a href="https://xctuality.com/" target="_blank">
                    <div className="image_div">
                      <img src={LAN} className="spono_img" />
                    </div>
                  </a>
                </Column>
                <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
                  <a href="https://www.sistic.com.sg/" target="_blank">
                    <div className="image_div">
                      <img src={JOSHUA} className="spono_img" />
                    </div>
                  </a>
                </Column>
                <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
                  <a
                    href="https://www.facebook.com/musicanddramacompany/"
                    target="_blank"
                  >
                    <div className="image_div">
                      <img src={ANDREW} className="spono_img" />
                    </div>
                  </a>
                </Column>
                <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
                  <a href="https://www.crossratioent.com/" target="_blank">
                    <div className="image_div">
                      <img src={MASTER} className="spono_img" />
                    </div>
                  </a>
                </Column>
                <Column xl={2} lg={3} md={3} sm={6} className="spon_col">
                  <a href="https://www.facebook.com/MoCaYN/" target="_blank">
                    <div className="image_div">
                      <img src={KRISFLYER} className="spono_img" />
                    </div>
                  </a>
                </Column>
              </Row>
            </Column>
            <span className="separator"></span>
            <Column xl={3} lg={12} md={12} sm={12}>
              <Row>
                <Column>
                  <Text as="div" className="highlight-title">
                    PRIVILEGE CARD
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column xl={12} lg={3} md={3} sm={12} className="spon_col">
                  <div className="image_div">
                    <img src={PRIVILEGELOGO} className="spono_img" />
                  </div>
                </Column>
              </Row>
            </Column>
          </Row>
        </Grid>
        {/* <FeatureArtists
          className="feature-artists-class"
          featureTitle={translate("overview.FeatureArtists.featureTitle")}
          items={[
            {
              image: HANS,
              // name: "SPONSOR 1",
              // // designation: "CHIEF CONDUCTOR (FROM JULY 2020)",
              // description:
              //   " From July 2020, Hans Graf assumes the title of Chief Conductor of the Singapore Symphony Orchestra. His collaboration with the SSO has been described as “solidly musical” (Bachtrack) and an “inspired partnership” (The Straits Times).",
            },
            {
              image: LAN,
              // name: "SPONSOR 2",
              // // designation: "CONDUCTOR LAUREATE",
              // description:
              //   "Lan Shui is renowned for his abilities as an orchestral builder and for his passion in commissioning, premiering and recording new works by leading Asian composers. He is the recipient of several international awards, as well as Singapore’s Cultural Medallion – our highest accolade in the arts. In 2019.",
            },
            {
              image: JOSHUA,
              // name: "SPONSOR 3",
              // // designation: "ASSOCIATE CONDUCTOR",
              // description:
              //   "Second Prize winner of the 2008 Dimitri Mitropoulos International Competition, Singaporean conductor Joshua Tan’s rise to prominence on the international scene has been marked by recent successful debuts in Carnegie Hall, Philharmonie Berlin, Mariinsky Hall, Bunkamura, Shanghai, Beijing and Taiwan.",
            },
            {
              image: ANDREW,
              // name: "ANDREW LITTON ",
              // designation: "PRINCIPAL GUEST CONDUCTOR",
              // description:
              //   "SSO’s Principal Guest Conductor from the 2017/18 season, American conductor Andrew Litton began his conducting career with the Bournemouth Symphony Orchestra and has since conducted all over the world in numerous engagements.",
            },
            {
              image: JOSHUA,
              // name: "SPONSOR 3",
              // // designation: "ASSOCIATE CONDUCTOR",
              // description:
              //   "Second Prize winner of the 2008 Dimitri Mitropoulos International Competition, Singaporean conductor Joshua Tan’s rise to prominence on the international scene has been marked by recent successful debuts in Carnegie Hall, Philharmonie Berlin, Mariinsky Hall, Bunkamura, Shanghai, Beijing and Taiwan.",
            },
            {
              image: ANDREW,
              // name: "ANDREW LITTON ",
              // designation: "PRINCIPAL GUEST CONDUCTOR",
              // description:
              //   "SSO’s Principal Guest Conductor from the 2017/18 season, American conductor Andrew Litton began his conducting career with the Bournemouth Symphony Orchestra and has since conducted all over the world in numerous engagements.",
            },
          ]}
        ></FeatureArtists> */}
        {/* <Grid style={{ paddingTop: "25px", paddingBottom: "30px" }}>
          <Row>
            <Column xs={12}>
              <a
                href="https://docs.google.com/document/u/1/d/e/2PACX-1vTsU5FCGYWe7PmsQyGXvN5MAOXiqRF4f-SaN_W57svB-5t5wquGD2mju-GVsbij11YlKXwv25mfJKmi/pub"
                target="_blank"
                style={{
                  textDecoration: "none",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button primary className="feature_btn">
                  Click Here to View FAQ
                </Button>
              </a>
            </Column>
          </Row>
        </Grid> */}
      </div>
      {/* <TransactionStatus
        isOpen={props.transactionDetails.isOpen}
        onClose={onClose}
        transactionSuccessFlag={props.transactionDetails.transactionSuccessFlag}
        transactionData={props.transactionDetails.transactionData}
        username={props.loginDetails.keycloak}
      /> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
