import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Text } from "@StixNext/mojito-ui";
import Moment from "react-moment";

import banner from "../../assets/images/main.jpg";
// import main from "../../assets/images/buy_product.jpg";
import Keycloak from "keycloak-js";
import { Column, Grid, Row } from "@StixNext/mojito-ui";

import * as actions from "../../actions/Login";
import * as transActions from "../../actions/Transaction";

function GroupPassSuccess(props) {
  return (
    <>
      <Grid>
        <div className="success_section">
          <Row>
            <Column xl={6} xs={12}>
              <Row style={{ paddingBottom: "5px" }}>
                {/* <img
                  src={main}
                  alt="icon"
                  style={{ width: "100%", height: "auto" }}
                /> */}
              </Row>
            </Column>
            <Column xl={6} xs={12}>
              <Row style={{ paddingBottom: "5px" }}>
                <Text as="h4" className="success_txt">
                  Thank you
                  <br />
                  謝謝
                </Text>
                <Text as="h5" className="redeem_txt">
                  You have successfully redeemed your access pass for JJ
                  Lin「SANCTUARY FINALE」Virtual Concert!
                  <br />
                  您已成功兌換 JJ 林俊傑《聖所 FINALE》終點站線上演唱會的門票!
                </Text>
                <Text as="h5" className="redeem_txt">
                  Please return to this page on 10th July 2021 and log in using
                  your SISTIC account to access the virtual concert.
                  <br />
                  請於 2021 年 7 月 10 日返回此頁面,
                  並登陸您的SISTIC用戶來觀看線上演唱會。
                </Text>
              </Row>
            </Column>
          </Row>
        </div>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
  productDetails: state.Products,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data) => dispatch(transActions.fetchTransaction(data)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupPassSuccess);
