import React, { useEffect, useState } from "react";
import { Shell, Carousel, Card, Text } from "@StixNext/mojito-ui";
import {
  Column,
  Grid,
  Row,
  Scheduler,
  RightArrowIcon,
  Dropdown,
} from "@StixNext/mojito-ui";
import * as Images from "./common/watch-free";
import * as actions from "../actions/Workshop";
import { connect } from "react-redux";
// import moment from "moment";
import DialogueImage from "./../assets/images/dialogue_placeholder.png";
import MentorshipImage from "./../assets/images/mentorship_placeholder.jpg";



const sortByProperty = (property) => {
  return (a, b) => {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;
    return 0;
  };
};

const options = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const Workshops = (props) => {
  let { fetchWorkshop, workshopList, dateSelected, setDateSelected } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [category, setCategory] = useState([]);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  let defultChannel = [
    { channelName: "Dialogue Session", channelId: 1 },
    { channelName: "Masterclasses", channelId: 2 },
    { channelName: "Mentorship/Clinics", channelId: 3 },
  ];

  const [channel, setChannel] = useState(defultChannel);
  const [mobchannel, setMobChannel] = useState(defultChannel);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(defultChannel[0]);
  const [scheduleData, setScheduleData] = useState(workshopList);
  const [slidesEvents, setSlidesEvents] = useState([]);


  useEffect(() => {
    props.fetchWorkshop();
  }, []);

  useEffect(() => {
    let newList = workshopList.sort(sortByProperty("date"))
    let defaultDate = newList && newList[0] && newList[0].date
    if(defaultDate){
      setDateSelected(defaultDate)
    }
    // setSlidesEvents(workshopList)
  }, [workshopList]);

  useEffect(() => {
    handleChannelView();
  }, [selectedChannel]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const handleChannelView = () => {
    let filterChannel = defultChannel.filter(
      (item) => item.channelId == selectedChannel
    );
    let defultValue = defultChannel.length > 0 ? defultChannel[0] : {};
    filterChannel = filterChannel.length == 0 ? [defultValue] : filterChannel;

    setMobChannel(filterChannel);
  };

  let timeLine = [
    { time: "10:00", endTime: "1100", startTime: "1000" },
    { time: "11:00", endTime: "1200", startTime: "1100" },
    { time: "12:00", endTime: "1300", startTime: "1200" },
    { time: "13:00", endTime: "1400", startTime: "1300" },
    { time: "14:00", endTime: "1500", startTime: "1400" },
    { time: "15:00", endTime: "1600", startTime: "1500" },
    { time: "16:00", endTime: "1700", startTime: "1600" },
    { time: "17:00", endTime: "1800", startTime: "1700" },
    { time: "18:00", endTime: "1900", startTime: "1800" },
    { time: "19:00", endTime: "2000", startTime: "1900" },
    { time: "20:00", endTime: "2100", startTime: "2000" },
    { time: "21:00", endTime: "2200", startTime: "2100" },
    { time: "22:00", endTime: "2300", startTime: "2200" },
    { time: "23:00", endTime: "2400", startTime: "2300" },
  ];

  const setScheduleFilter = (date) => {
    let data = workshopList.sort(sortByProperty("date")).filter(
      (item) => {
        return (
         item.date == dateSelected
        );
      }
    );
    setScheduleData(data);
    setSlidesEvents(data);
  };

  useEffect(() => {
    let dates = [];
    let todaySlides = [];
    let today = new Date().toLocaleDateString();
    let selectDate = null;
    workshopList.map((programmes) => {
      programmes["buttonEnabled"] = programmes.QAFlag ? true : false;
      dates.push(programmes.date);
      let programmesDate = new Date(programmes.date).toLocaleDateString();
    });
    if (todaySlides.length != 0) setSlidesEvents(todaySlides);
    let uniqueDates = dates.sort().filter((v, i, a) => a.indexOf(v) === i);
    let a = [];
    let b = [];
    uniqueDates = uniqueDates.map((date) => {
      if (!a.includes(date)) {
        a.push(date);
        b.push({ text: date, value: date });
      }

      return { text: date, value: date };
    });
    setDateOptions(b);
    setScheduleFilter(dateSelected);
  }, [workshopList]);

  useEffect(() => {
    setScheduleFilter(dateSelected);
  }, [dateSelected]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const handleClick = (eventId) => {
    props.history.push(`/workshopdetail/${eventId}`);
  };

  useEffect(() => {
    let cat = [];
    workshopList.map((video) => {
      let { Category } = video;
      if (Category) {
        cat.push(Category);
      }
    });
    let uniqueCat = cat.sort().filter((v, i, a) => a.indexOf(v) === i);
    setCategory(uniqueCat);
  }, [workshopList]);

  const filterCategory = (data, selectedCategory) => {
    let { Category = null } = data;

    if (Category) {
      let cat = Category.toString().split(",");
      let result = cat.filter((d) => d == selectedCategory.toString());
      if (result.length > 0) {
        return data;
      }
    }
  };

  return (
    <Shell.Body className={"css-work-shop"}>
            <>
              {slidesEvents.length > 0 && (
                <Grid>
                  <Row>
                    <Column xl={12}>
                      <Carousel
                        autoPlay={false}
                        interval={5000}
                        slidesPresented={windowWidth > 760 ? 3 : 1}
                        Indictor={!(windowWidth <= 760)}
                        mobileView={windowWidth <= 760}
                        className={"carousel"}
                      >
                        {slidesEvents
                          .sort(sortByProperty("startTime"))
                          .map((slide, index) => {
                            let images = Images[
                              `Image_100${slide.id
                                .toString()
                                .slice(
                                  slide.id.toString().length - 1,
                                  slide.id.toString().length
                                )}`
                            ]
                              ? Images[
                                  `Image_100${slide.id
                                    .toString()
                                    .slice(
                                      slide.id.toString().length - 1,
                                      slide.id.toString().length
                                    )}`
                                ]
                              : Images[`defaultImage`];
                            return (
                              <Card
                                key={index}
                                className={"video-slides"}
                                onClick={() => handleClick(slide?.id)}
                                noPadding={false}
                                noBoxShadow={false}
                                noBorder={true}
                                noBorderRadius={true}
                              >
                                <img src={ slide.programmeTrack == "Master-Class" ? images :  slide.programmeTrack == "Dialogue Session" ? DialogueImage : MentorshipImage} alt="" />
                                <div>
                                  <div
                                    className={"title-tiles vod-tiles"}
                                    dangerouslySetInnerHTML={{
                                      __html: slide?.title,
                                    }}
                                  />
                                  <p as="p" style={{fontSize:"16px"}} >
                                    {"Duration: " + slide?.duration + " mins"}{" "}
                                   
                                  </p>
                                  {/* <p as="p" >
                                    {"Uploaded on: " +moment(slide?.updateDate).format('ll') }
                                    <br />
                                  </p> */}
                                </div>
                              </Card>
                            );
                          })}
                      </Carousel>
                    </Column>
                  </Row>
                </Grid>
              )}
              <Grid>
                <Row className="scheduler-class">
                  <Column xl={12}>
                    <div id="dropdown_123" style={{ display: "flex" }}>
                      <h1
                        style={{ paddingTop: 10, margin: "0px 15px 15px 15px" ,fontWeight: "bold",fontSize:"25px"  }}
                        className="schedule-title"
                      >
                        SCHEDULE 
                      </h1>
                      <Dropdown
                        id="dropdown"
                        options={dateOptions}
                        mulitple={false}
                        Width={250}
                        value={dateSelected}
                        placeholder="Select a Date"
                        onChange={(item) => {
                          setDateSelected(item.value);
                        }}
                      />
                      <Dropdown
                        id="dropdown_channel"
                        options={defultChannel.map((channel, index) => {
                          return {
                            text: channel.channelName,
                            value: channel.channelId,
                          };
                        })}
                        mulitple={false}
                        value={selectedChannel}
                        placeholder="Select a channel"
                        onChange={(item) => setSelectedChannel(item.value)}
                      />
                    </div>
                  </Column>
                </Row>

                <Row>
                  <Column xl={12}>
                    <Scheduler
                      className="web_schedule"
                      scheduleData={scheduleData}
                      timeLine={timeLine}
                      channel={channel}
                      eventOnClick={handleClick}
                      titleKey={"title"}
                      subTitleKey={"featuredPresenters"}
                      defultTextButton={"Live Q&A "}
                    ></Scheduler>
                    <Scheduler
                      className="mob_schedule"
                      scheduleData={scheduleData}
                      timeLine={timeLine}
                      channel={mobchannel}
                      eventOnClick={handleClick}
                      titleKey={"title"}
                      subTitleKey={"featuredPresenters"}
                      defultTextButton={"Live Q&A "}
                    ></Scheduler>
                  </Column>
                </Row>
              </Grid>

              {/* <Grid>
              <Row>
                <Column>
                  <Text as={"h6"} className="schedule">
                    SCHEDULE
                  </Text>
                </Column>
              </Row>
            </Grid> */}
            </>

    </Shell.Body>
  );
};
const mapStateToProps = (state) => ({
  workshopList: state.Workshop.workshopList,
  dateSelected: state.Workshop.dateSelected,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWorkshop: () => dispatch(actions.fetchWorkshop()),
  setDateSelected: (date) => dispatch(actions.setDateSelected(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Workshops);
