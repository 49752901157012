import { database } from ".";

let db = database;

// if (process.env.REACT_APP_NODE_ENV === "development") {
//   db.useEmulator("localhost", 9000);
// }

export const getSessionMessages = db.ref(`/messages`);

export const getSessionReactions = db.ref(`/${process.env.REACT_APP_CLIENTID}/${process.env.REACT_APP_NODE_ENV}/reactions`);

export const getVideoReactions = (videoId) => db.ref(`/${process.env.REACT_APP_CLIENTID}/${process.env.REACT_APP_NODE_ENV}/reactions/` + videoId);

export const getSessionDetails = (userId) => db.ref(`/sessions/` + userId);

export const getUserSession = db.ref(`/usersession`);
