const init = {
  videoOnDemandList: [],
  videoOnDemandDetail: [],
  nextPrevId: "",
};

export const VideoOnDemand = (state = init, action) => {
  switch (action.type) {
    case "UPDATE_VIDEOONDEMAND_VALUE":
      return { ...state, [action.field]: action.value };
    case "UPDATE_VIDEOONDEMAND_DETAIL_VALUE":
      let result = action.value.filter(
        (o) => !state.videoOnDemandDetail.some((v) => v.id === o.id)
      );
      console.log("stateprops", { ...state, videoOnDemandDetail: result });
      let prevState = { ...state, videoOnDemandDetail: [] };
      console.log("prevstateprops", { ...state, videoOnDemandDetail: [] });
      return { ...prevState, videoOnDemandDetail: result };
    default:
      return state;
  }
};
