import { getWorkshop, getProgrammes } from "./commonapi/mockData";
import { api } from "./commonapi/apiPaths";

export const changeWorkshopValues = (field, value) => {
  return {
    type: "UPDATE_WORKSHOP_VALUE",
    field,
    value,
  };
};

export const fetchWorkshop = () => {
  return (dispatch) => {
    // getWorkshop()
    return fetch(api.fetchWorkshop)
      .then((res) => res.json())
      .then((data) => {
        let NewworkshopList = data.data.map(({ date, duration, ...rest }) => ({
          date: new Date(date).toLocaleString("en-SG", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          }),
          startTime: new Date(date)
            .toLocaleString("en-US", {
              hour: "2-digit",
              hour12: false,
              minute: "2-digit",
            })
            .replace(":", ""),
          endTime: new Date(new Date(date).getTime() + duration * 60000)
            .toLocaleString("en-US", {
              hour: "2-digit",
              hour12: false,
              minute: "2-digit",
            })
            .replace(":", ""),
          duration,
          ...rest,
        }));

        dispatch(changeWorkshopValues("workshopList", NewworkshopList));
      })
      .catch((err) => {});
  };
};

export const fetchWorkshopDetail = (progammeId) => {
  return (dispatch) => {
    // getWorkshop()
    return fetch(api.fetchWorkshop)
      .then((res) => res.json())
      .then((data) => {
        let programmeDetail = data.data
          .filter(function (item) {
            return item.id === progammeId;
          })
          .map(({ date, duration, ...rest }) => ({
            date,
            startTime: new Date(date)
              .toLocaleString("en-US", {
                hour: "2-digit",
                hour12: false,
                minute: "2-digit",
              })
              .replace(":", ""),
            endTime: new Date(new Date(date).getTime() + duration * 60000)
              .toLocaleString("en-US", {
                hour: "2-digit",
                hour12: false,
                minute: "2-digit",
              })
              .replace(":", ""),
            duration,
            ...rest,
          }));
        dispatch(changeWorkshopValues("workshopDetail", programmeDetail));
      })
      .catch((err) => {});
  };
};

export const setDateSelected = (date) => {
  return (dispatch) => {
    dispatch(changeWorkshopValues("dateSelected", date));
  };
};

export const fetchDryRunDetail = (progammeId) => {
  return (dispatch) => {
    getWorkshop()
      // return fetch(api.fetchProgrames)
      .then((data) => {
        let programmeDetail = data.filter(function (item) {
          return item.id === progammeId;
        });
        dispatch(changeWorkshopValues("workshopDetail", programmeDetail));
      })
      .catch((err) => {});
  };
};
